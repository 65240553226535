import { styled } from '@kivra/react-components';

export const ModalButtonLayout = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& button': {
    marginTop: '$spacing-20',
    width: '66% !important',
  },
});
