import { Button, Display, Flex, Tooltip } from '@kivra/react-components';
import { ArrowLeftIcon } from '@kivra/react-components/icons';
import React from 'react';
import { HR } from '../../components/HR';
import { TopLeftPosition } from '../../components/TopLeftPosition';
import { useOrganization } from '../../contexts/organizationContext';
import { useCopy } from '../../globalContext';
import { ROUTE_START } from '../../routes/Router';
import { goBack } from '../../routes/history';
import { Members } from '../organization/components/Members';

export const Admin = (): React.JSX.Element => {
  const { organization } = useOrganization();
  const getCopy = useCopy();

  return (
    <>
      <TopLeftPosition>
        <Tooltip
          visibility="on-hover"
          placement="right"
          title={getCopy('btn__backward')}
        >
          <Button
            onClick={() => goBack(ROUTE_START)}
            variant="link"
            tab-index={0}
          >
            <Button.Icon iconComponent={() => <ArrowLeftIcon size={24} />} />
          </Button>
        </Tooltip>
      </TopLeftPosition>

      <Flex>
        <Display size="medium" style={{ marginBottom: 8 }}>
          {organization.name}
        </Display>
      </Flex>
      <HR />
      <Members />
    </>
  );
};
