import React from 'react';
import { Flex, Margin, Body } from '@kivra/react-components';
import type { IdentityProviderOption } from '../util/identityProviders';
import { useConfig } from '../globalContext';
import { CheckboxCard } from './CheckboxCard';

type Props = {
  identityProviderOptions: IdentityProviderOption[];
  selected: string[];
  onChange: (identityProviders: string[]) => void;
};

export const IDPConfig: React.FC<Props> = ({
  identityProviderOptions,
  onChange,
  selected = [],
}) => {
  const config = useConfig();
  const handleToggleOption = (toggleId: string): void => {
    if (selected.includes(toggleId)) {
      onChange(selected.filter(selectedId => selectedId !== toggleId));
    } else {
      onChange(selected.concat(toggleId));
    }
  };
  return (
    <>
      {identityProviderOptions.map(({ logoPath, value, label }) => (
        <CheckboxCard
          key={value}
          onChange={() => handleToggleOption(value)}
          checked={selected.includes(value)}
        >
          <Flex alignItems="center">
            {logoPath && (
              <Margin right={16}>
                <img
                  src={`${config.kivra_static_origin}${logoPath}`}
                  width={28}
                  height={28}
                />
              </Margin>
            )}
            <Body size="medium">{label}</Body>
          </Flex>
        </CheckboxCard>
      ))}
    </>
  );
};
