import { getCopyString } from '@kivra/sdk/copy-consumer';
import type { HeimdallQueryParameters } from '@sender-portal-fe/util-shared/src/sdk/authentication/types/heimdall';
import { createContext, useContext } from 'react';
import type { GetCopyArguments } from './copy';
import type { AppOptions, Config, Copy } from './types';

interface GlobalContext {
  copy: Copy;
  config: Config;
  appOptions: AppOptions;
  global: Window;
  heimdall: HeimdallQueryParameters;
}

export const GlobalContext = createContext<GlobalContext | null>(null);

const { Consumer: GlobalContextConsumer, Provider: GlobalContextProvider } =
  GlobalContext;

export { GlobalContextConsumer, GlobalContextProvider };

const hookCreator =
  <T>(map: (gc: GlobalContext) => T) =>
  (): T => {
    const global = useContext(GlobalContext);
    if (!global) {
      throw new TypeError('Global context is null');
    }
    return map(global);
  };

export const useConfig = hookCreator(g => g.config);
export const useCopy = hookCreator(g =>
  getCopyString<GetCopyArguments>(g.copy)
);
export const useAppOptions = hookCreator(g => g.appOptions);
export const useHeimdallParameters = hookCreator(g => g.heimdall);
export const useGlobal = hookCreator(g => g.global);
