import { ApiResponseError } from '@kivra/sdk/common';
import type { FixThisAnyLater } from '@kivra/sdk/types/util/any';
import { signOut } from './signOut';

export const handleUnauthenticatedRequest =
  <
    OriginalRequest extends (
      ...args: FixThisAnyLater[]
    ) => Promise<FixThisAnyLater>,
  >(
    request: OriginalRequest
  ) =>
  (...args: Parameters<typeof request>): ReturnType<typeof request> =>
    request(...args).catch(async (error: unknown) => {
      if (error instanceof ApiResponseError && error.httpStatusCode === 401) {
        await signOut();
      } else {
        throw error;
      }
    }) as ReturnType<OriginalRequest>;
