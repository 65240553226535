import { Button, Caption, Heading, styled } from '@kivra/react-components';
import { PlusIcon, TrashIcon } from '@kivra/react-components/icons';
import type { SenderSearchResult } from '@kivra/sdk/senders';
import React from 'react';
import { useCopy } from '../../globalContext';
import { SenderIcon } from '../SenderIcon';
import { SenderTypeLabel } from '../SenderTypeLabel';
import { CardSection } from '../card/CardSection';

const CardSectionContent = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '$spacing-16',
  justifyContent: 'space-between',
  overflow: 'hidden',
});

const SenderTexts = styled.div({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  gap: '$spacing-2',
  overflow: 'hidden',
});

const SenderNameAndType = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '$spacing-8',
});

type Props = {
  onAdd?: () => void;
  onRemove?: () => void;
  sender: SenderSearchResult;
  added?: boolean;
};

export const SenderResult: React.FC<Props> = ({
  sender,
  onAdd,
  onRemove,
  added,
}) => {
  const getCopy = useCopy();
  const { name, key, type, iconUrl } = sender;
  return (
    <CardSection>
      <CardSectionContent>
        <SenderIcon url={iconUrl} />
        <SenderTexts>
          <SenderNameAndType>
            <Heading size="small">{name}</Heading>
            <SenderTypeLabel type={type} />
          </SenderNameAndType>
          <Caption
            color="$text-secondary"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {key.split('_')[1]}
          </Caption>
        </SenderTexts>
        {added ? (
          <Button disabled variant="secondary">
            {getCopy('sender_backoffice__added')}
          </Button>
        ) : (
          <>
            {onAdd && (
              <Button onClick={onAdd}>
                <Button.Icon iconComponent={PlusIcon} />
                {getCopy('btn__add')}
              </Button>
            )}
            {onRemove && (
              <Button onClick={onRemove} variant="secondary">
                <Button.Icon iconComponent={TrashIcon} />
                {getCopy('btn__remove')}
              </Button>
            )}
          </>
        )}
      </CardSectionContent>
    </CardSection>
  );
};
