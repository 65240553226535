import { Margin, MenuItem } from '@kivra/react-components';
import {
  ArrowDownIcon,
  LogoutIcon,
  SenderIcon,
  SettingsIcon,
} from '@kivra/react-components/icons';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useLoggedInUser } from '../../contexts/loggedInUserContext';
import { useCopy } from '../../globalContext';
import { ROUTE_ADMIN, ROUTE_ORGANIZATION_LIST } from '../../routes/Router';
import { goTo } from '../../routes/history';
import { signOut } from '../../util/signOut';
import { ContextMenu } from '../contextMenu/ContextMenu';

export const TopBarMenu = (): React.JSX.Element => {
  const getCopy = useCopy();
  const { isAdmin } = useLoggedInUser();
  const { path } = useRouteMatch();
  const isAdminView = path.startsWith(ROUTE_ADMIN);

  return (
    <Margin left={16}>
      <ContextMenu icon={ArrowDownIcon} isSubtle>
        {setIsOpen => (
          <>
            {isAdmin &&
              (isAdminView ? (
                <MenuItem
                  title={getCopy('sender_backoffice__organizations')}
                  onClick={() => {
                    setIsOpen(false);
                    goTo(ROUTE_ORGANIZATION_LIST);
                  }}
                  size="small"
                  icon={SenderIcon}
                />
              ) : (
                <MenuItem
                  title={getCopy('sender_backoffice__manage_admins')}
                  onClick={() => {
                    setIsOpen(false);
                    goTo(ROUTE_ADMIN);
                  }}
                  size="small"
                  icon={SettingsIcon}
                />
              ))}
            <MenuItem
              title={getCopy('misc_option__logout')}
              onClick={async () => {
                setIsOpen(false);
                await signOut();
              }}
              size="small"
              icon={LogoutIcon}
            />
          </>
        )}
      </ContextMenu>
    </Margin>
  );
};
