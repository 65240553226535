import { Overline, styled } from '@kivra/react-components';
import type { SenderType } from '@kivra/sdk/types/sender';
import React from 'react';
import { useCopy } from '../globalContext';

type Props = {
  type: SenderType;
};

const StyledOverline = styled(Overline)<Props>(({ type }) => ({
  display: 'inline-block',
  padding: ['$spacing-2', '$spacing-8'],
  backgroundColor:
    type === 'tenant' ? '$surface-neutral' : '$surface-highlight',
  borderRadius: '$radius-xsmall',
  verticalAlign: 'middle',
}));

export const SenderTypeLabel = ({ type }: Props): React.JSX.Element => {
  const getCopy = useCopy();
  return (
    <StyledOverline type={type}>
      {getCopy(
        type === 'tenant'
          ? 'sender_backoffice__sender_type_postal'
          : 'sender_backoffice__sender_type_receipt'
      )}
    </StyledOverline>
  );
};
