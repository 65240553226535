export const listStrings = (things: string[]): string => {
  if (things.length === 1) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return things[0]!;
  } else if (things.length > 1) {
    const lastThing = things.pop();
    return `${things.join(', ')} & ${lastThing}`;
  }
  return '';
};
