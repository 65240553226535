import React, { useState } from 'react';
import { Button, Heading } from '@kivra/react-components';
import { useServiceMessage } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import { NarrowLayout } from '../components/NarrowLayout';
import { Card } from '../components/card/Card';
import { SenderSearch } from '../components/senderSearch/SenderSearch';
import { CardPadding } from '../components/card/CardPadding';
import { addSenderToOrganization } from '../util/senderRequests';
import { useOrganization } from '../contexts/organizationContext';
import { goBack, getParentPath } from '../routes/history';
import { useCopy } from '../globalContext';
import { ModalButtonLayout } from '../components/ModalButtonLayout';
import { OrganizationDialogHeader } from '../components/OrganizationDialogHeader';
import { Form } from '../components/Form';
import { listStrings } from '../util/listStrings';
import type { NamedSender } from '../types';

export const AddSenders = (): React.JSX.Element => {
  const [senders, setSenders] = useState<NamedSender[]>([]);
  const { organization, setOrganization } = useOrganization();
  const { addServiceMessage } = useServiceMessage();
  const [isLoading, setIsLoading] = useState(false);
  const getCopy = useCopy();

  const handleAddSenders = (): void => {
    setIsLoading(true);
    Promise.all(
      senders.map(sender =>
        addSenderToOrganization(sender.key, organization.id)
      )
    )
      .then(() => {
        setOrganization({
          ...organization,
          senders: organization.senders.concat(
            senders.map(sender => ({
              ...sender,
              key: sender.key,
              iconUrl: sender.iconUrl,
            }))
          ),
        });
        const senderNames = senders.map(({ name }) => name);
        addServiceMessage({
          title: getCopy('sender_backoffice__action_feedback_add_to', {
            thing: listStrings(senderNames),
            organizationName: organization.name,
          }),
          body: '',
          variant: 'success',
          fadeout: true,
        });
      })
      .catch((e: unknown) => {
        addServiceMessage({
          title: getCopy('error_generic__title'),
          body: (e as Error).message,
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
        goBack(getParentPath());
      });
  };

  return (
    <NarrowLayout>
      <Card>
        <CardPadding>
          <Form onSubmit={handleAddSenders}>
            <OrganizationDialogHeader organization={organization} />
            <Heading size="medium">
              {getCopy('sender_backoffice__add_senders')}
            </Heading>
            <SenderSearch
              autoFocus
              placeholder={getCopy('sender_backoffice__search_for_senders')}
              onChange={senders => setSenders(senders)}
              addedSenderKeys={organization.senders.map(({ key }) => key)}
            />
            <ModalButtonLayout>
              <Button
                progress={isLoading}
                variant="primary"
                type="submit"
                disabled={senders.length === 0}
              >
                {getCopy('sender_backoffice__add_to_organization')}
              </Button>
              <Button variant="link" onClick={() => goBack(getParentPath())}>
                {getCopy('btn__cancel_dialog')}
              </Button>
            </ModalButtonLayout>
          </Form>
        </CardPadding>
      </Card>
    </NarrowLayout>
  );
};
