import { resourceKeyToResourceId } from '@kivra/sdk/common';
import type { Order, TableColumnData } from '@kivra/react-components';
import {
  Flex,
  Margin,
  MenuItem,
  Table,
  TableCell,
  TableRow,
} from '@kivra/react-components';
import { TrashIcon } from '@kivra/react-components/icons';
import React, { useEffect, useState } from 'react';
import { SenderIcon } from '../../../components/SenderIcon';
import { ContextMenu } from '../../../components/contextMenu/ContextMenu';
import {
  ContextMenuTableCell,
  NameTableCell,
  getSorting,
  stableSort,
} from '../../../components/table';
import { useLoggedInUser } from '../../../contexts/loggedInUserContext';
import { useCopy } from '../../../globalContext';
import type { NamedSender } from '../../../types';

interface Props {
  senders: NamedSender[];
  handleRemoveSender: (senderKey: string) => void;
}

enum SortableTableColumn {
  name = 'name',
  type = 'type',
  key = 'key',
}

type RowData = {
  [SortableTableColumn.name]: string;
  [SortableTableColumn.type]: NamedSender['type'];
  [SortableTableColumn.key]: string;
  senderKey: string;
  icon: string;
};

const senderToRow = ({ name, key, type, iconUrl }: NamedSender): RowData => ({
  [SortableTableColumn.name]: name,
  [SortableTableColumn.type]: type,
  [SortableTableColumn.key]: resourceKeyToResourceId(key),
  icon: iconUrl,
  senderKey: key,
});

export const SendersTable: React.FC<Props> = ({
  senders,
  handleRemoveSender,
}) => {
  const [order, setOrder] = useState<Order>('ascending');
  const [orderBy, setOrderBy] = useState(SortableTableColumn.name);
  const [rows, setRows] = useState<RowData[]>([]);
  const getCopy = useCopy();
  const { isAdmin } = useLoggedInUser();

  const getSenderTypeCopy = (type: NamedSender['type']): string => {
    switch (type) {
      case 'tenant':
        return getCopy('sender_backoffice__sender_type_postal');
      case 'store':
      case 'chain':
        return getCopy('sender_backoffice__sender_type_receipt');
      default:
        return '-';
    }
  };

  useEffect(() => {
    const rows = senders.map(senderToRow);
    setRows(stableSort<RowData>(rows, getSorting<string>(order, orderBy)));
  }, [senders]);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: SortableTableColumn
  ): void => {
    const isDesc = orderBy === property && order === 'descending';
    const newOrder = isDesc ? 'ascending' : 'descending';
    setOrder(newOrder);
    setOrderBy(property);
    setRows(stableSort(rows, getSorting<string>(newOrder, property)));
  };

  const tableColumns: Array<TableColumnData<SortableTableColumn>> = [
    {
      id: SortableTableColumn.name,
      title: getCopy('sender_backoffice__senders_column_name'),
      sortable: true,
    },
    {
      id: SortableTableColumn.type,
      title: getCopy('sender_backoffice__senders_column_type'),
      sortable: true,
    },
    {
      id: SortableTableColumn.key,
      title: getCopy('sender_backoffice__senders_column_key'),
      sortable: true,
    },
    { id: 'contextMenu' },
  ];

  const rowsElements = rows.map(rowData => (
    <TableRow key={rowData.key}>
      <NameTableCell>
        <Flex alignItems="center">
          <SenderIcon url={rowData.icon} />
          <Margin left={24}>{rowData.name}</Margin>
        </Flex>
      </NameTableCell>
      <TableCell>{getSenderTypeCopy(rowData.type)}</TableCell>
      <TableCell>{rowData.key}</TableCell>
      <ContextMenuTableCell>
        {isAdmin && (
          <ContextMenu isSubtle>
            {setIsOpen => (
              <MenuItem
                title={getCopy('sender_backoffice__remove_sender')}
                size="small"
                icon={TrashIcon}
                onClick={() => {
                  setIsOpen(false);
                  handleRemoveSender(rowData.senderKey);
                }}
              />
            )}
          </ContextMenu>
        )}
      </ContextMenuTableCell>
    </TableRow>
  ));

  return (
    <>
      <Table<SortableTableColumn>
        columns={tableColumns}
        handleRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        rowsElements={rowsElements}
        dataTestId="SendersTable"
      />
    </>
  );
};
