import { userSession } from '@kivra/sdk/identity';
import { decodeIdToken } from '@sender-portal-fe/util-shared/src/sdk/authentication/heimdall';
import type { PersonaIdToken } from '@sender-portal-fe/util-shared/src/sdk/authentication/types/heimdall';
import React from 'react';
import { AccessDenied } from '../components/AccessDenied';
import { useConfig } from '../globalContext';
import { LoggedInUserContextProvider } from './loggedInUserContext';

type Props = {
  children?: React.ReactNode;
};

export const LoggedInUserProvider = ({
  children,
}: Props): React.JSX.Element => {
  const config = useConfig();
  let loggedInUser = null;
  const idToken = userSession.getSession()?.userId;

  if (!idToken) {
    throw new Error('no user id');
  }

  const decodedToken = decodeIdToken<PersonaIdToken>(idToken);
  loggedInUser = {
    email: decodedToken.email,
    isAdmin:
      decodedToken.organizations?.includes(
        config.sender_backoffice_admin_organization_id
      ) || false,
    organizationIds: decodedToken.organizations || [],
  };

  if (loggedInUser.organizationIds.length === 0) {
    return <AccessDenied />;
  }

  return (
    <LoggedInUserContextProvider value={{ loggedInUser }}>
      {children}
    </LoggedInUserContextProvider>
  );
};
