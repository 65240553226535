export const normalizeUrl = ({
  pathname,
  hash,
}: {
  pathname: string;
  hash: string;
}): void => {
  let url = (pathname + hash.replace('#', '')).replace('//', '/');

  // Remove any query params
  url = url.replace(/\?.*$/, '');

  // Normalize the path by adding a leading slash and removing slash at the end
  if (!url.startsWith('/')) {
    url = `/${url}`;
  }
  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }

  // Check if `url` is an old url, if so, map it to the new url
  // otherwise use `url`.

  window.history.replaceState(null, 'Kivra', url);
};
