import React, { useState } from 'react';
import { NarrowLayout } from '../../components/NarrowLayout';
import { Card } from '../../components/card/Card';
import { CardPadding } from '../../components/card/CardPadding';
import { goTo } from '../../routes/history';
import { ROUTE_ORGANIZATION_LIST } from '../../routes/Router';
import { useCopy } from '../../globalContext';
import { CreateOrganizationStep } from './components/CreateOrganizationStep';
import { ConfigureIdentityProvidersStep } from './components/ConfigureIdentityProvidersStep';
import { CompleteStep } from './components/CompleteStep';
import { AddUsersStep } from './components/AddUsersStep';
import { GuideProgressIndicator } from './components/GuideProgressIndicator';

enum WizardStep {
  createOrganization = 0,
  selectIdentityProviders = 1,
  addUsers = 2,
  complete = 3,
}

export const CreateOrganizationGuide = (): React.JSX.Element => {
  const [step, setStep] = useState<WizardStep>(WizardStep.createOrganization);
  const [organizationId, setOrganizationId] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [identityProviderIds, setIdentityProviders] = useState<string[]>([]);
  const getCopy = useCopy();

  const goToOrganizationPage = (): void => {
    goTo(`${ROUTE_ORGANIZATION_LIST}/${organizationId}`);
  };

  return (
    <NarrowLayout>
      <Card data-test-id="CreateOrganizationGuide">
        <CardPadding>
          <GuideProgressIndicator
            currentStep={step}
            steps={[
              getCopy('sender_backoffice__guide_progress_create'),
              getCopy('sender_backoffice__guide_progress_login_method'),
              getCopy('sender_backoffice__guide_progress_users'),
            ]}
          />
          {step === WizardStep.createOrganization && (
            <CreateOrganizationStep
              onOrganizationCreated={(organizationId, organizationName) => {
                setOrganizationId(organizationId);
                setOrganizationName(organizationName);
                setStep(WizardStep.selectIdentityProviders);
              }}
            />
          )}
          {step === WizardStep.selectIdentityProviders && (
            <ConfigureIdentityProvidersStep
              organizationId={organizationId}
              organizationName={organizationName}
              onSuccess={identityProviderIds => {
                setIdentityProviders(identityProviderIds);
                setStep(WizardStep.addUsers);
              }}
              onCancel={goToOrganizationPage}
            />
          )}
          {step === WizardStep.addUsers && (
            <AddUsersStep
              organizationId={organizationId}
              organizationName={organizationName}
              identityProviderIds={identityProviderIds}
              onCancel={goToOrganizationPage}
              onSuccess={() => setStep(WizardStep.complete)}
            />
          )}
          {step === WizardStep.complete && (
            <CompleteStep onContinue={goToOrganizationPage} />
          )}
        </CardPadding>
      </Card>
    </NarrowLayout>
  );
};
