import { styled } from '@kivra/react-components';

type Props = {
  fullWidthBorder?: boolean;
};

export const CardSection = styled.div<Props>(({ fullWidthBorder = false }) => ({
  padding: ['$spacing-16', fullWidthBorder ? '$spacing-16' : 0],
  margin: fullWidthBorder ? 0 : [0, '$spacing-24'],
  borderBottom: '1px solid $border-subtle',
  '&:last-child': {
    borderBottom: 0,
  },
}));
