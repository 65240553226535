import { getHeimdallQueryParameters } from '@sender-portal-fe/util-shared/src/sdk/authentication/heimdall';
import { updateRequestConfig } from '@kivra/sdk/common';
import { normalizeUrl } from '@sender-portal-fe/util-shared/src/bootstrap/normalizeUrl';
import type { HeimdallQueryParameters } from '@sender-portal-fe/util-shared/src/sdk/authentication/types/heimdall';
import type { AppOptions, Config } from '../types';
import { getAppOptions } from './appOptions';
import { getConfig } from './config';
import { initSentry } from './sentry';

export async function bootstrap(): Promise<{
  config: Config;
  appOptions: AppOptions;
  heimdallQueryParameters: HeimdallQueryParameters;
}> {
  const config = await getConfig();
  updateRequestConfig({ senderOrigin: config.sender_api_origin });
  initSentry(config);
  const appOptions = getAppOptions(window.location.href);
  const heimdallQueryParameters = getHeimdallQueryParameters(
    window.location.href
  );
  normalizeUrl(window.location);

  return { config, appOptions, heimdallQueryParameters };
}
