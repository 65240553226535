import {
  getOrganizationMembers as unhandledGetOrganizationMembers,
  getPersonasOrganization as unhandledGetPersonaOrganization,
  inviteUserToOrganization as unhandledInviteUserToOrganization,
  removeUserFromOrganization as unhandledRemoveUserFromOrganization,
  setOrganizationIdentityProviders as unhandledSetOrganizationIdentityProviders,
} from '@sender-portal-fe/util-shared/src/sdk/personas/personas';
import { handleUnauthenticatedRequest } from './unauthenticatedHandler';

export const getPersonasOrganization = handleUnauthenticatedRequest(
  unhandledGetPersonaOrganization
);

export const getOrganizationMembers = handleUnauthenticatedRequest(
  unhandledGetOrganizationMembers
);

export const inviteUserToOrganization = handleUnauthenticatedRequest(
  unhandledInviteUserToOrganization
);

export const removeUserFromOrganization = handleUnauthenticatedRequest(
  unhandledRemoveUserFromOrganization
);

export const setOrganizationIdentityProviders = handleUnauthenticatedRequest(
  unhandledSetOrganizationIdentityProviders
);
