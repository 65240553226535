import {
  addSenderToOrganization as unhandledAddSenderToOrganization,
  createOrganization as unhandledCreateOrganization,
  deleteOrganization as unhandledDeleteOrganization,
  getOrganization as unhandledGetOrganization,
  getOrganizations as unhandledGetOrganizations,
  removeSenderFromOrganization as unhandledRemoveSenderFromOrganization,
} from '@sender-portal-fe/util-shared/src/sdk/senders/organizations';
import { getOrganizationSenders as unhandledGetOrganizationSenders } from '@sender-portal-fe/util-shared/src/sdk/senders/senders';
import { handleUnauthenticatedRequest } from './unauthenticatedHandler';

export const getOrganizations = handleUnauthenticatedRequest(
  unhandledGetOrganizations
);

export const getOrganization = handleUnauthenticatedRequest(
  unhandledGetOrganization
);

export const createOrganization = handleUnauthenticatedRequest(
  unhandledCreateOrganization
);

export const deleteOrganization = handleUnauthenticatedRequest(
  unhandledDeleteOrganization
);

export const removeSenderFromOrganization = handleUnauthenticatedRequest(
  unhandledRemoveSenderFromOrganization
);

export const addSenderToOrganization = handleUnauthenticatedRequest(
  unhandledAddSenderToOrganization
);

export const getOrganizationSenders = handleUnauthenticatedRequest(
  unhandledGetOrganizationSenders
);
