import React from 'react';
import { Body, styled } from '@kivra/react-components';
import type { IdentityProviderOption } from '../../../util/identityProviders';
import { useConfig } from '../../../globalContext';

export const IDPOption: React.FC<IdentityProviderOption> = ({
  label,
  logoPath,
}) => {
  const config = useConfig();
  return (
    <Wrapper>
      {logoPath && (
        <Logo
          src={`${config.kivra_static_origin}${logoPath}`}
          width={20}
          height={20}
        />
      )}
      <Body size="medium">{label}</Body>
    </Wrapper>
  );
};

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: ['$spacing-8', 0],
});

const Logo = styled.img({
  marginRight: '$spacing-16',
});
