import React from 'react';
import { Dialog } from '@kivra/react-components';
import { useCopy } from '../globalContext';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  text: string;
  open: boolean;
}

export const DeleteDialog: React.FC<Props> = ({
  onConfirm,
  onCancel,
  text,
  open,
}) => {
  const getCopy = useCopy();
  return (
    <Dialog.Destructive
      actionText={getCopy('btn__remove')}
      cancelText={getCopy('btn__cancel_dialog')}
      onConfirm={onConfirm}
      onClose={onCancel}
      ariaLabelCloseIcon={getCopy('btn__cancel_dialog')}
      onDismissFocusRef={undefined}
      open={open}
      title={text}
    />
  );
};
