import React from 'react';

type Props = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  children?: React.ReactNode;
};

export const Form = ({ onSubmit, children }: Props): React.JSX.Element => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onSubmit(e);
  };
  return <form onSubmit={handleSubmit}>{children}</form>;
};
