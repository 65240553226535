import type { HeimdallQueryParameters } from '@sender-portal-fe/util-shared/src/sdk/authentication/types/heimdall';
import type { AppOptions, Config, Copy } from './types';

export interface AppState {
  copy: Copy | null;
  config: Config | null;
  appOptions: AppOptions | null;
  bootError: Error | null;
  heimdall: HeimdallQueryParameters;
}

export const initState = {
  copy: null,
  config: null,
  appOptions: null,
  bootError: null,
  authenticationMethods: null,
  heimdall: {},
};

export type Actions =
  | { type: 'update_copy'; copy: Partial<Copy> }
  | { type: 'update_config'; config: Partial<Config> }
  | { type: 'update_app_options'; appOptions: Partial<AppOptions> }
  | { type: 'update_heimdall'; heimdall: HeimdallQueryParameters }
  | { type: 'set_boot_error'; error: Error };

export function reducer(state: AppState, actions: Actions): AppState {
  const updateState = <T extends keyof AppState>(
    oldState: AppState,
    part: T,
    newPart: Partial<AppState[T]> | null
  ): AppState => {
    if (!oldState[part]) {
      return { ...oldState, [part]: newPart };
    } else {
      return {
        ...oldState,
        [part]: newPart && Object.assign(state[part] ?? {}, newPart),
      };
    }
  };
  switch (actions.type) {
    case 'update_copy':
      return updateState(state, 'copy', actions.copy);
    case 'update_config':
      return updateState(state, 'config', actions.config);
    case 'update_app_options':
      return updateState(state, 'appOptions', actions.appOptions);
    case 'update_heimdall':
      return updateState(state, 'heimdall', actions.heimdall);
    case 'set_boot_error':
      return {
        ...state,
        bootError: actions.error,
      };
  }
  return state;
}
