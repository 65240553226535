import React, { useState } from 'react';
import { Button, Heading } from '@kivra/react-components';
import { useServiceMessage } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import { NarrowLayout } from '../components/NarrowLayout';
import { Card } from '../components/card/Card';
import { CardPadding } from '../components/card/CardPadding';
import { useOrganization } from '../contexts/organizationContext';
import { getParentPath, goBack } from '../routes/history';
import { useCopy } from '../globalContext';
import { ModalButtonLayout } from '../components/ModalButtonLayout';
import { OrganizationDialogHeader } from '../components/OrganizationDialogHeader';
import { IDPConfig } from '../components/IDPConfig';
import {
  getIdpLabel,
  identityProviderOptions,
} from '../util/identityProviders';
import { setOrganizationIdentityProviders } from '../util/personasRequests';
import { Form } from '../components/Form';
import { listStrings } from '../util/listStrings';

export const UpdateIdentityProviders = (): React.JSX.Element => {
  const { organization, setOrganization } = useOrganization();
  const [identityProviderIds, setIdentityProviderIds] = useState<string[]>(
    organization.idProviders
  );
  const { addServiceMessage } = useServiceMessage();
  const getCopy = useCopy();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (): void => {
    setIsLoading(true);
    setOrganizationIdentityProviders(identityProviderIds, organization.id)
      .then(() => {
        setOrganization({
          ...organization,
          idProviders: identityProviderIds,
        });
        addServiceMessage({
          title: getCopy('sender_backoffice__action_feedback_set_idp', {
            authMethods: listStrings(identityProviderIds.map(getIdpLabel)),
          }),
          body: '',
          variant: 'success',
          fadeout: true,
        });
      })
      .catch((e: unknown) => {
        addServiceMessage({
          title: getCopy('error_generic__title'),
          body: (e as Error).message,
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
        goBack(getParentPath());
      });
  };

  return (
    <NarrowLayout>
      <Card>
        <CardPadding>
          <Form onSubmit={handleSubmit}>
            <OrganizationDialogHeader organization={organization} />
            <Heading size="medium">
              {getCopy('sender_backoffice__select_idps_for_org')}
            </Heading>
            <IDPConfig
              identityProviderOptions={identityProviderOptions}
              selected={identityProviderIds}
              onChange={setIdentityProviderIds}
            />
            <ModalButtonLayout>
              <Button
                progress={isLoading}
                variant="primary"
                onClick={handleSubmit}
              >
                {getCopy('sender_backoffice__add_to_organization')}
              </Button>
              <Button variant="link" onClick={() => goBack(getParentPath())}>
                {getCopy('btn__cancel_dialog')}
              </Button>
            </ModalButtonLayout>
          </Form>
        </CardPadding>
      </Card>
    </NarrowLayout>
  );
};
