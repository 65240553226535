/**
 * Taken directly from https://github.com/kivra/tf-contsvc-sndr-ptl/blob/e5f5e1b2b759d2c394b4a14a95b4ca458c016664/frontend/packages/util-shared/components/contextMenu/ContextMenu.tsx
 */
// TODO: Start using the ContextMenu from util-shared instead
import type { IconProps } from '@kivra/react-components/icons';
import { Button, MenuList, Popover } from '@kivra/react-components';
import { MenuIcon } from '@kivra/react-components/icons';
import React, { useRef, useState } from 'react';

type Props = {
  isSubtle?: boolean;
  icon?: (props: IconProps) => React.JSX.Element;
  children: (
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => React.ReactNode;
};

export const ContextMenu = ({
  children,
  icon = MenuIcon,
}: Props): React.JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={e => {
          e.stopPropagation();
          setIsOpen(true);
        }}
        variant="link"
        dataTestId="ContextMenuTrigger"
      >
        <Button.Icon iconComponent={icon} />
      </Button>
      <Popover
        anchorRef={anchorRef}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        drawerOnSmallScreenSize
        placement="bottom-end"
        style={{ backgroundColor: '$border-distinct' }}
      >
        <MenuList>{children(setIsOpen)}</MenuList>
      </Popover>
    </>
  );
};
