import React from 'react';
import { Router as ReactRouter, Switch, Route } from 'react-router-dom';
import { history } from './history';
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';

export const ROUTE_LOGIN = '/login';
export const ROUTE_ORGANIZATION_LIST = '/organizations';
export const ROUTE_ORGANIZATION = `/:organizationId`;
export const ROUTE_INVITE_USER = `/invite-user`;
export const ROUTE_ADD_SENDER = `/add-sender`;
export const ROUTE_SET_AUTH = `/set-auth`;
export const ROUTE_CREATE_ORGANIZATION = `/create-organization`;
export const ROUTE_ADMIN = `/admin`;
export const ROUTE_START = `/start`;

export type RouteParams = {
  organizationId?: string;
};

export const Router = (): React.JSX.Element => {
  return (
    <ReactRouter history={history}>
      <Switch>
        <Route
          path={[
            ROUTE_ORGANIZATION_LIST,
            ROUTE_CREATE_ORGANIZATION,
            ROUTE_ADMIN,
            ROUTE_START,
          ]}
          component={PrivateRoutes}
        />
        <Route path="*" component={PublicRoutes} />
      </Switch>
    </ReactRouter>
  );
};
