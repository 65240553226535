import React, { useState } from 'react';
import { FullPageLoader, ErrorPage, useOnMount } from '@kivra/react-components';
import { getPersonasOrganization } from '../util/personasRequests';
import { getOrganization } from '../util/senderRequests';
import { useConfig } from '../globalContext';
import type { DetailedOrganization } from './organizationContext';
import { OrganizationContextProvider } from './organizationContext';

type Props = {
  children?: React.ReactNode;
};

export const AdminOrganizationProvider = ({
  children,
}: Props): React.JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [organization, setOrganization] = useState<DetailedOrganization>();
  const config = useConfig();
  const organizationId = config.sender_backoffice_admin_organization_id;

  const updateOrganization = (org: DetailedOrganization): void => {
    setOrganization(org);
  };

  useOnMount(() => {
    Promise.all([
      getOrganization(organizationId),
      getPersonasOrganization(organizationId),
    ])
      .then(([organizationBase, organization]) => {
        setOrganization({
          ...organizationBase,
          ...organization,
          senders: [],
        });
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  if (hasError && !isLoading) {
    return <ErrorPage useDefaultTexts />;
  }

  return (
    <OrganizationContextProvider
      value={{ organization, isLoading, setOrganization: updateOrganization }}
    >
      {isLoading ? <FullPageLoader /> : children}
    </OrganizationContextProvider>
  );
};
