import { FullPageLoader, useOnMount } from '@kivra/react-components';
import React, { useState } from 'react';
import { useConfig } from '../globalContext';
import { getOrganization, getOrganizations } from '../util/senderRequests';
import { useLoggedInUser } from './loggedInUserContext';
import type { OrganizationBase } from './organizationsContext';
import { OrganizationsContextProvider } from './organizationsContext';

type Props = {
  children?: React.ReactNode;
};

export const OrganizationsProvider = ({
  children,
}: Props): React.JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const { isAdmin, organizationIds } = useLoggedInUser();
  const [organizations, setOrganizations] = useState<OrganizationBase[]>([]);
  const config = useConfig();

  useOnMount(async () => {
    void (
      isAdmin
        ? getOrganizations()
        : Promise.all(organizationIds.map(getOrganization))
    )
      .then(organizations => {
        setOrganizations(
          organizations
            .filter(
              ({ id }) => id !== config.sender_backoffice_admin_organization_id
            )
            .map(org => ({ ...org, name: org.name.trim() }))
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  return (
    <OrganizationsContextProvider
      value={{ organizations, isLoading, setOrganizations }}
    >
      {isLoading ? <FullPageLoader /> : children}
    </OrganizationsContextProvider>
  );
};
