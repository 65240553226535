import React from 'react';
import type { DetailedOrganization } from '../contexts/organizationContext';
import type { OrganizationBase } from '../contexts/organizationsContext';
import { useConfig } from '../globalContext';
import { SenderIcon } from './SenderIcon';

export const OrganizationIcon = ({
  organization,
  size = 40,
}: {
  organization?: DetailedOrganization | OrganizationBase;
  size?: number;
}): React.JSX.Element => {
  const config = useConfig();
  const senders = organization?.senders || [];
  const getIconUrl = (senderKey: string): string =>
    `${config.kivra_static_origin}/img/tenant/${
      senderKey.split('_')[1] || 'default'
    }/icon.png`;

  return senders.length === 1 && senders[0]?.type === 'tenant' ? (
    <SenderIcon url={getIconUrl(senders[0].key)} size={size} />
  ) : (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#EEE" />
      <path
        d="M23.845 12.072H22.31v-2.56A.512.512 0 0021.797 9h-4.095a.512.512 0 00-.512.512v2.56h-1.536a.512.512 0 00-.511.511v17.405c0 .283.228.512.511.512h8.191a.512.512 0 00.512-.512V12.583a.512.512 0 00-.512-.511z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M17.703 20.262H9.511a.512.512 0 00-.512.512v9.215c0 .283.229.512.512.512h8.19a.512.512 0 00.512-.512v-9.215a.512.512 0 00-.511-.512z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M29.988 17.19h-8.19a.512.512 0 00-.513.513v12.285c0 .283.23.512.512.512h8.19a.512.512 0 00.513-.512V17.703a.512.512 0 00-.512-.512z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M26.405 26.917h-1.024a.512.512 0 00-.511.512V30.5h2.047V27.43a.512.512 0 00-.512-.512z"
        fill="#6A6A6A"
      />
      <path
        d="M14.118 27.429h-1.024a.512.512 0 00-.511.512v2.56h2.047v-2.56a.512.512 0 00-.512-.512zM24.868 21.286h-1.024a.512.512 0 01-.512-.512V19.75c0-.283.23-.512.512-.512h1.024c.283 0 .512.23.512.512v1.024c0 .282-.229.512-.512.512zM27.94 21.286h-1.023a.512.512 0 01-.512-.512V19.75c0-.283.229-.512.512-.512h1.023c.283 0 .512.23.512.512v1.024c0 .282-.229.512-.512.512zM24.868 24.358h-1.024a.512.512 0 01-.512-.512v-1.024c0-.283.23-.512.512-.512h1.024c.283 0 .512.229.512.512v1.024c0 .282-.229.512-.512.512zM27.94 24.358h-1.023a.512.512 0 01-.512-.512v-1.024c0-.283.229-.512.512-.512h1.023c.283 0 .512.229.512.512v1.024c0 .282-.229.512-.512.512zM12.583 24.87H11.56a.512.512 0 01-.512-.512v-1.024c0-.283.23-.512.512-.512h1.024c.283 0 .512.229.512.512v1.024c0 .282-.23.511-.512.511zM15.655 24.87h-1.023a.512.512 0 01-.512-.512v-1.024c0-.283.229-.512.512-.512h1.023c.283 0 .512.229.512.512v1.024c0 .282-.229.511-.512.511z"
        fill="#EEE"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="19.75"
          y1="9"
          x2="19.75"
          y2="30.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABA8A8" />
          <stop offset="1" stopColor="#D9D9D9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="13.607"
          y1="20.262"
          x2="13.607"
          y2="30.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AFAFAF" />
          <stop offset="1" stopColor="#636363" stopOpacity=".64" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="25.892"
          y1="17.191"
          x2="25.892"
          y2="30.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161" />
          <stop offset="1" stopColor="#939393" stopOpacity=".68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
