import { fetchCopy } from '@kivra/sdk/copy-consumer';
import type { Copy } from '../types';

const languages = {
  localPath: {
    sv: '/sv.json',
    en: '/en.json',
  },
};

export function getLocalCopy(): Promise<Copy> {
  return fetchCopy<Copy>(languages.localPath, 'se').then(({ copy }) => copy);
}
