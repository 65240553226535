import React from 'react';
import { Checkbox, styled } from '@kivra/react-components';

type Props = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  children?: React.ReactNode;
};

export const CheckboxCard = ({
  checked,
  onChange,
  children,
}: Props): React.JSX.Element => (
  <StyledCheckboxCard
    checked={checked}
    onClick={() => {
      onChange(!checked);
    }}
  >
    <div>{children}</div>
    <Checkbox label="" checked={checked} />
  </StyledCheckboxCard>
);

const StyledCheckboxCard = styled.div<Pick<Props, 'checked'>>(
  ({ checked }) => ({
    marginBottom: '$spacing-8',
    height: '56px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [0, '$spacing-24'],
    cursor: 'pointer',
    border: '1px solid $border-subtle',
    boxShadow: checked ? '0px 1px 8px rgba(0, 0, 0, 0.12)' : 'none',
    borderRadius: '$radius-medium',
    width: '100%',
  })
);
