import type { TextField } from '@kivra/react-components';
import { Flex, Margin, SearchBar, styled } from '@kivra/react-components';
import { debounce } from '@kivra/sdk/common';
import type { SenderSearchResult } from '@kivra/sdk/senders';
import { useServiceMessage } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import { searchSender } from '@sender-portal-fe/util-shared/src/sdk/senders/senders';
import React, { useEffect, useRef, useState } from 'react';
import { useCopy } from '../../globalContext';
import { useRefState } from '../../util/useStateRef';
import { Card } from '../card/Card';
import { CardSection } from '../card/CardSection';
import { SenderResult } from './SenderResult';
import { debounceTimeoutMs } from './constants';

type Props = {
  onChange: (senders: SenderSearchResult[]) => void;
  addedSenderKeys?: string[];
  placeholder?: string;
} & Pick<React.ComponentProps<typeof TextField>, 'autoFocus'>;

const MIN_LENGTH = 1;

export const SenderSearch: React.FC<Props> = ({
  onChange,
  addedSenderKeys = [],
  placeholder,
  autoFocus,
}) => {
  const [senderResults, setSenderResults] = useState<SenderSearchResult[]>([]);
  const [sendersToAdd, setSendersToAdd] = useState<SenderSearchResult[]>([]);
  const [inputText, setInputText] = useState('');
  const [searchString, setSearchString] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [latestSearch, latestSearchRef, setLatestSearch] = useRefState(null);
  const { addServiceMessage } = useServiceMessage();
  const getCopy = useCopy();

  const handleGetSenders = (inputText: string): void => {
    setInputText(inputText);
    search.current(inputText);
  };

  const search = useRef(
    debounce((text: string) => {
      setSearchString(text);
    }, debounceTimeoutMs)
  );

  useEffect(() => {
    if (inputText.length >= MIN_LENGTH) {
      const timestamp = new Date();
      setLatestSearch(timestamp);
      setIsLoading(true);
      searchSender(searchString)
        .then(results => {
          if (latestSearchRef.current === timestamp) {
            setSenderResults(results.hits);
            setIsLoading(false);
          }
        })
        .catch(() => {
          addServiceMessage({
            title: getCopy('error_generic__title'),
            body: '',
            variant: 'error',
          });
        });
    } else {
      setSenderResults([]);
      setLatestSearch(null);
      setIsLoading(false);
    }
  }, [searchString]);

  const addSender = (sender: SenderSearchResult): void => {
    const senders = sendersToAdd.concat([sender]);
    setSendersToAdd(senders);
    onChange(senders);
  };

  const removeSender = (sender: SenderSearchResult): void => {
    const senders = sendersToAdd.filter(({ key }) => key !== sender.key);
    setSendersToAdd(senders);
    onChange(senders);
  };

  const unavailableSenders = [...sendersToAdd.map(({ key }) => key)];
  const availableSenders = senderResults.filter(
    ({ key }) => !unavailableSenders.includes(key)
  );

  const noResults =
    !isLoading &&
    inputText.length >= MIN_LENGTH &&
    latestSearch !== null &&
    availableSenders.length === 0;

  return (
    <>
      <Card>
        {sendersToAdd.map(sender => (
          <SenderResult
            sender={sender}
            key={sender.key}
            onRemove={() => {
              removeSender(sender);
            }}
          />
        ))}
      </Card>
      <ResultsWrapper>
        <Margin top={sendersToAdd.length > 0 ? 32 : 4} bottom={4}>
          <SearchBar
            clearIconText=""
            variant="secondary"
            value={inputText}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onChange={(value: string) => handleGetSenders(value)}
            isLoading={isLoading}
          />
        </Margin>
        <Card className="searchResultContainer" data-test-id="SearchResult">
          {availableSenders.map(sender => (
            <SenderResult
              sender={sender}
              key={sender.key}
              onAdd={() => addSender(sender)}
              added={addedSenderKeys.includes(sender.key)}
            />
          ))}
          {noResults && (
            <CardSection>
              <Flex justifyContent="center">
                {getCopy('sender_backoffice__no_senders_found', {
                  searchString: inputText,
                })}
              </Flex>
            </CardSection>
          )}
        </Card>
      </ResultsWrapper>
    </>
  );
};

const ResultsWrapper = styled.div({
  '& .searchResultContainer': {
    maxHeight: 290,
    overflowY: 'auto',
  },
});
