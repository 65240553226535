import React from 'react';
import { useServiceMessage } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import { useOrganizations } from '../contexts/organizationsContext';
import { deleteOrganization } from '../util/senderRequests';
import { useCopy } from '../globalContext';
import { DeleteDialog } from './DeleteDialog';

type Props = {
  organizationId: string;
  isDialogOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
};

export const DeleteOrganization: React.FC<Props> = ({
  organizationId,
  isDialogOpen,
  onCancel,
  onSuccess,
}) => {
  const { addServiceMessage } = useServiceMessage();
  const { organizations, setOrganizations } = useOrganizations();
  const getCopy = useCopy();

  const handleDeleteOrganization = async (
    organizationToDelete: string
  ): Promise<void> => {
    try {
      await deleteOrganization(organizationId);
      setOrganizations(organizations.filter(({ id }) => id !== organizationId));
      addServiceMessage({
        title: getCopy(
          'sender_backoffice__action_feedback_delete_organization',
          { name: organizationToDelete }
        ),
        body: '',
        variant: 'success',
        fadeout: true,
      });
      onSuccess();
    } catch {
      addServiceMessage({
        title: getCopy('error_generic__title'),
        body: '',
        variant: 'error',
      });
      onCancel();
    }
  };

  const organizationToDelete =
    organizations.find(({ id }) => id === organizationId)?.name ||
    organizationId;
  const deleteOrganizationText = getCopy('sender_backoffice__delete_dialog', {
    thing: organizationToDelete,
  });

  return (
    <DeleteDialog
      onConfirm={() => {
        return handleDeleteOrganization(organizationToDelete);
      }}
      onCancel={() => {
        onCancel();
      }}
      text={deleteOrganizationText}
      open={isDialogOpen}
    />
  );
};
