import React from 'react';
import type { ColorToken, ColorValue } from '@kivra/react-components';
import {
  RoundedBackground,
  Flex,
  Body,
  css,
  StyleException,
} from '@kivra/react-components';

type Props = {
  email: string;
};

const validColors: Array<ColorToken | ColorValue> = [
  '$green-200',
  '$amber-200',
  '$indigo-200',
];

export const UserIcon = ({ email }: Props): React.JSX.Element => {
  const emailAsNumber = email.split('').reduce((number, char) => {
    return number + char.charCodeAt(0);
  }, 0);
  const selectedColor = validColors[emailAsNumber % validColors.length];
  const beforeAt = email.toLocaleLowerCase().split('@')[0];
  const names = (beforeAt ?? '').split('.');
  const firstName = names[0] ?? '';
  const lastName = names[1];

  const initials = `${firstName[0] ?? ''}${lastName ? lastName[0] : ''}`;
  return (
    <RoundedBackground
      backgroundColor={StyleException(selectedColor)}
      size={40}
    >
      <Flex alignItems="center" justifyContent="center">
        <Body
          color="$text-black"
          size="small"
          className={css({
            textTransform: names.length > 1 ? 'uppercase' : 'capitalize',
            fontWeight: 'bold',
          })}
        >
          {initials}
        </Body>
      </Flex>
    </RoundedBackground>
  );
};
