import { Caption, Flex, Margin } from '@kivra/react-components';
import { KivraIcon } from '@kivra/react-components/icons';
import React from 'react';
import type { DetailedOrganization } from '../contexts/organizationContext';
import { useConfig } from '../globalContext';
import { OrganizationIcon } from './OrganizationIcon';

type Props = {
  organization: DetailedOrganization;
};

export const OrganizationDialogHeader: React.FC<Props> = ({ organization }) => {
  const config = useConfig();
  return (
    <Flex alignItems="center" direction="column">
      {organization.id === config.sender_backoffice_admin_organization_id ? (
        <KivraIcon size={24} colorToken="$green-300" />
      ) : (
        <OrganizationIcon organization={organization} size={54} />
      )}
      <Margin top={8} bottom={32}>
        <Caption>{organization.name}</Caption>
      </Margin>
    </Flex>
  );
};
