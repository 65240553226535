import React, { useState } from 'react';
import { Margin, Button } from '@kivra/react-components';
import { useServiceMessage } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import { IDPConfig } from '../../../components/IDPConfig';
import { ModalButtonLayout } from '../../../components/ModalButtonLayout';
import { useCopy } from '../../../globalContext';
import { setOrganizationIdentityProviders } from '../../../util/personasRequests';
import {
  getIdpLabel,
  identityProviderOptions,
} from '../../../util/identityProviders';
import { Form } from '../../../components/Form';
import { listStrings } from '../../../util/listStrings';
import { StepHeading } from './StepHeading';

type Props = {
  organizationId: string;
  organizationName: string;
  onSuccess: (identityProviderIds: string[]) => void;
  onCancel: () => void;
};

export const ConfigureIdentityProvidersStep: React.FC<Props> = ({
  organizationId,
  organizationName,
  onSuccess,
  onCancel,
}) => {
  const getCopy = useCopy();
  const { addServiceMessage } = useServiceMessage();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedIdentityProviderIds, setSelectedIdentityProviderIds] =
    useState<string[]>([]);

  const handleAddIdentityProviders = (): void => {
    setIsLoading(true);
    setOrganizationIdentityProviders(
      selectedIdentityProviderIds,
      organizationId
    )
      .then(() => {
        addServiceMessage({
          title: getCopy('sender_backoffice__action_feedback_add_to', {
            thing: listStrings(selectedIdentityProviderIds.map(getIdpLabel)),
            organizationName,
          }),
          body: '',
          variant: 'success',
          fadeout: true,
        });
        setIsLoading(false);
        onSuccess(selectedIdentityProviderIds);
      })
      .catch(() => {
        addServiceMessage({
          title: getCopy('error_generic__title'),
          body: '',
          variant: 'error',
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <StepHeading>
        {getCopy('sender_backoffice__select_idps_for_org')}
      </StepHeading>
      <Margin top={16}>
        <Form onSubmit={handleAddIdentityProviders}>
          <IDPConfig
            identityProviderOptions={identityProviderOptions}
            selected={selectedIdentityProviderIds}
            onChange={(selected: string[]) =>
              setSelectedIdentityProviderIds(selected)
            }
          />
          <Margin top={56}>
            <ModalButtonLayout>
              <Button
                progress={isLoading}
                variant="primary"
                disabled={selectedIdentityProviderIds.length === 0}
                type="submit"
              >
                {getCopy('btn__continue')}
              </Button>
              <Button variant="link" onClick={onCancel}>
                {getCopy('sender_backoffice__skip_guide_step')}
              </Button>
            </ModalButtonLayout>
          </Margin>
        </Form>
      </Margin>
    </>
  );
};
