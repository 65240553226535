import {
  Caption,
  Flex,
  Margin,
  StyleException,
  css,
  styled,
} from '@kivra/react-components';
import { CheckmarkCircleIcon } from '@kivra/react-components/icons';
import React from 'react';

type Props = {
  steps: string[];
  currentStep: number;
};

type DotProps = {
  done: boolean;
  active: boolean;
  children?: React.ReactNode;
};

const Dot = styled.div<{ active: boolean }>(({ active }) => ({
  backgroundColor: active ? '$active-highlight' : '$surface-disabled',
  height: 10,
  width: 10,
  borderRadius: '$radius-circle',
}));

const Step = ({ done, active, children }: DotProps): React.JSX.Element => {
  return (
    <div className={css({ height: 14, width: 14, position: 'relative' })}>
      {done ? (
        <CheckmarkCircleIcon
          size={16}
          colorToken="$on-surface-disabled"
          style={{ display: 'block' }}
        />
      ) : (
        <Dot active={active} />
      )}
      <Caption
        className={css({
          position: 'absolute',
          top: 22,
          left: '50%',
          transform: 'translate(-50%, 0)',
          whiteSpace: 'nowrap',
        })}
        style={{ fontWeight: active ? 'bold' : 'normal' }}
      >
        {children}
      </Caption>
    </div>
  );
};

const Dash = styled('div')({
  width: 40,
  height: 1,
  borderBottom: `1px solid $text-secondary`,
  margin: StyleException('6px 16px 20px 16px'),
});

export const GuideProgressIndicator: React.FC<Props> = ({
  currentStep,
  steps,
}) => {
  return (
    <Margin bottom={40} style={{ alignSelf: 'center' }}>
      <Flex alignItems="flex-start">
        {steps.map((step, index) => {
          return (
            <React.Fragment key={step}>
              <Margin bottom={20}>
                <Step done={index < currentStep} active={index === currentStep}>
                  {step}
                </Step>
              </Margin>
              {index < steps.length - 1 && <Dash />}
            </React.Fragment>
          );
        })}
      </Flex>
    </Margin>
  );
};
