import React from 'react';
import { ErrorPage, Button } from '@kivra/react-components';
import { navigate } from '@kivra/sdk/common';
import { ROUTE_START } from '../routes/Router';
import { useCopy } from '../globalContext';

export const NotFound = (): React.JSX.Element => {
  const getCopy = useCopy();
  return (
    <ErrorPage title={getCopy('error__page_not_found')}>
      <Button onClick={() => navigate(ROUTE_START)}>
        {getCopy('campaigns__goto_startpage_button')}
      </Button>
    </ErrorPage>
  );
};
