import {
  Display,
  Flex,
  FullPageLoader,
  Margin,
  MenuItem,
  css,
} from '@kivra/react-components';
import {
  ArrowDownIcon,
  SettingsIcon,
  TrashIcon,
} from '@kivra/react-components/icons';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { BreadCrumb } from '../../components/BreadCrumb';
import { DeleteOrganization } from '../../components/DeleteOrganization';
import { HR } from '../../components/HR';
import { ContextMenu } from '../../components/contextMenu/ContextMenu';
import { useLoggedInUser } from '../../contexts/loggedInUserContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useCopy } from '../../globalContext';
import { ROUTE_SET_AUTH } from '../../routes/Router';
import { goTo, goUp } from '../../routes/history';
import { Members } from './components/Members';
import { Senders } from './components/Senders';

export const Organization = (): React.JSX.Element => {
  const { url } = useRouteMatch<{ organizationId: string }>();
  const getCopy = useCopy();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { organizationIds, isAdmin } = useLoggedInUser();
  const shouldShowBreadCrumb = isAdmin || organizationIds.length > 1;

  const { organization, isLoading } = useOrganization();

  if (isLoading) {
    return <FullPageLoader />;
  }

  const displayName = organization.name;

  return (
    <>
      {shouldShowBreadCrumb && <BreadCrumb />}
      <Flex
        alignItems="center"
        className={css({ marginBottom: '$spacing-8' })}
        data-test-id="OrganizationHeader"
      >
        <Display size="medium" style={{ marginBottom: 0 }}>
          {displayName}
        </Display>
        {isAdmin && (
          <Margin left={24}>
            <ContextMenu icon={ArrowDownIcon}>
              {setIsOpen => (
                <>
                  <MenuItem
                    title={getCopy('sender_backoffice__select_idps_for_org')}
                    onClick={() => {
                      setIsOpen(false);
                      goTo(`${url}${ROUTE_SET_AUTH}`);
                    }}
                    size="small"
                    icon={SettingsIcon}
                  />
                  <MenuItem
                    title={getCopy('sender_backoffice__delete_organization')}
                    onClick={() => {
                      setIsOpen(false);
                      setShowDeleteDialog(true);
                    }}
                    size="small"
                    icon={TrashIcon}
                  />
                </>
              )}
            </ContextMenu>
          </Margin>
        )}
      </Flex>
      <HR />
      <Members />
      <Senders />
      <DeleteOrganization
        isDialogOpen={showDeleteDialog}
        organizationId={organization.id}
        onCancel={() => {
          setShowDeleteDialog(false);
        }}
        onSuccess={() => {
          setShowDeleteDialog(false);
          goUp();
        }}
      />
    </>
  );
};
