import {
  Flex,
  Heading,
  InternalLink,
  css,
  styled,
} from '@kivra/react-components';
import { ArrowRightIcon } from '@kivra/react-components/icons';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useOrganization } from '../contexts/organizationContext';
import { goTo } from '../routes/history';

const Row = styled.div({
  display: 'flex',
  '& p': {
    whiteSpace: 'pre',
    lineHeight: '18px',
  },
  marginBottom: '$spacing-32',
  textTransform: 'capitalize',
});

export const BreadCrumb = (): React.JSX.Element => {
  const { url, path } = useRouteMatch();
  const pathSegments = path.split('/').filter(entry => entry);
  const urlSegments = url.split('/').filter(entry => entry);
  const displayPath = pathSegments.map((segment, index) => {
    if (segment.startsWith(':')) {
      const paramName = segment.substring(1);
      switch (paramName) {
        case 'organizationId':
          return useOrganization().organization.name;
        default:
          return urlSegments[index];
      }
    }
  });
  const current = displayPath.pop();
  return (
    <Row>
      <Flex alignItems="baseline" gap="$spacing-8">
        {displayPath.map((segment, index) => {
          if (!segment) return null;
          const path = `/${urlSegments.slice(0, index + 1).join('/')}`;
          return (
            <InternalLink
              key={path}
              onClick={() => goTo(path)}
              text={segment}
              icon={() => (
                <ArrowRightIcon
                  size={16}
                  colorToken="$text-primary"
                  className={css({ marginLeft: '$spacing-8' })}
                />
              )}
              iconPosition="after"
            />
          );
        })}
        <Heading size="xsmall">{current}</Heading>
      </Flex>
    </Row>
  );
};
