import { Flex, Heading } from '@kivra/react-components';
import React from 'react';

type Props = {
  count?: number;
  children?: React.ReactNode;
};

export const ListHeading = ({ children, count }: Props): React.JSX.Element => (
  <Flex alignItems="baseline">
    <Heading size="medium" style={{ margin: 0 }}>
      {children}
      {count !== undefined && ` (${count})`}
    </Heading>
  </Flex>
);
