import type { Order, TableColumnData } from '@kivra/react-components';
import {
  Flex,
  Margin,
  MenuItem,
  Table,
  TableCell,
  TableRow,
  styled,
} from '@kivra/react-components';
import {
  EnvelopeIcon,
  PersonAddIcon,
  SettingsIcon,
  TrashIcon,
} from '@kivra/react-components/icons';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { DeleteOrganization } from '../../../components/DeleteOrganization';
import { OrganizationIcon } from '../../../components/OrganizationIcon';
import { ContextMenu } from '../../../components/contextMenu/ContextMenu';
import {
  ContextMenuTableCell,
  getSorting,
  stableSort,
} from '../../../components/table';
import { useLoggedInUser } from '../../../contexts/loggedInUserContext';
import type { OrganizationBase } from '../../../contexts/organizationsContext';
import { useCopy } from '../../../globalContext';
import {
  ROUTE_ADD_SENDER,
  ROUTE_INVITE_USER,
  ROUTE_SET_AUTH,
} from '../../../routes/Router';
import { goTo } from '../../../routes/history';

interface Props {
  organizations: OrganizationBase[];
}

enum SortableTableColumn {
  name = 'name',
  tenants = 'tenants',
  created = 'created',
}

type RowData = {
  id: string;
  [SortableTableColumn.name]: string;
  [SortableTableColumn.tenants]: string;
  [SortableTableColumn.created]: string;
};

const organizationToRow = ({
  id,
  name,
  createdAt,
  senders,
}: OrganizationBase): RowData => ({
  id: id,
  [SortableTableColumn.name]: name,
  [SortableTableColumn.tenants]: senders.length.toString(),
  [SortableTableColumn.created]: createdAt,
});

export const OrganizationsTable: React.FC<Props> = ({ organizations }) => {
  const [order, setOrder] = useState<Order>('ascending');
  const [orderBy, setOrderBy] = useState(SortableTableColumn.name);
  const [rows, setRows] = useState<RowData[]>([]);
  const getCopy = useCopy();
  const { path } = useRouteMatch();
  const [organizationIdToDelete, setOrganizationIdToDelete] = useState('');
  const { isAdmin } = useLoggedInUser();

  useEffect(() => {
    const rows = organizations.map(organizationToRow);
    setRows(stableSort<RowData>(rows, getSorting<string>(order, orderBy)));
  }, [organizations]);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: SortableTableColumn
  ): void => {
    const isDesc = orderBy === property && order === 'descending';
    const newOrder = isDesc ? 'ascending' : 'descending';
    setOrder(newOrder);
    setOrderBy(property);
    setRows(stableSort(rows, getSorting<string>(newOrder, property)));
  };

  const tableColumns: Array<TableColumnData<SortableTableColumn>> = [
    {
      id: SortableTableColumn.name,
      title: getCopy('sender_backoffice__members_column_name'),
      sortable: true,
    },
    {
      id: SortableTableColumn.tenants,
      title: getCopy('sender_backoffice__senders'),
      sortable: true,
    },
    {
      id: SortableTableColumn.created,
      title: getCopy('sender_backoffice__created'),
      sortable: true,
    },
    { id: 'contextMenu' },
  ];

  const rowsElements = rows.map(rowData => {
    const organizationPath = `${path}/${rowData.id}`;
    return (
      <TableRow onClick={() => goTo(organizationPath)} key={rowData.id}>
        <NameTableCell>
          <Flex alignItems="center">
            <Margin right={24}>
              <OrganizationIcon
                organization={organizations.find(({ id }) => id === rowData.id)}
              />
            </Margin>
            {rowData.name}
          </Flex>
        </NameTableCell>
        <TableCell>{rowData.tenants}</TableCell>
        <TableCell>{new Date(rowData.created).toLocaleDateString()}</TableCell>
        <ContextMenuTableCell>
          <ContextMenu isSubtle>
            {setIsOpen => (
              <>
                <MenuItem
                  title={getCopy('sender_backoffice__invite_user')}
                  onClick={() => {
                    setIsOpen(false);
                    goTo(`${organizationPath}${ROUTE_INVITE_USER}`);
                  }}
                  size="small"
                  icon={PersonAddIcon}
                />
                {isAdmin && (
                  <MenuItem
                    title={getCopy('sender_backoffice__add_sender')}
                    onClick={() => {
                      setIsOpen(false);
                      goTo(`${organizationPath}${ROUTE_ADD_SENDER}`);
                    }}
                    size="small"
                    icon={EnvelopeIcon}
                  />
                )}
                <MenuItem
                  title={getCopy('sender_backoffice__select_idps_for_org')}
                  onClick={() => {
                    setIsOpen(false);
                    goTo(`${organizationPath}${ROUTE_SET_AUTH}`);
                  }}
                  size="small"
                  icon={SettingsIcon}
                />
                {isAdmin && (
                  <MenuItem
                    title={getCopy('sender_backoffice__delete_organization')}
                    onClick={() => {
                      setIsOpen(false);
                      setOrganizationIdToDelete(rowData.id);
                    }}
                    size="small"
                    icon={TrashIcon}
                  />
                )}
              </>
            )}
          </ContextMenu>
        </ContextMenuTableCell>
      </TableRow>
    );
  });

  return (
    <>
      <Table<SortableTableColumn>
        columns={tableColumns}
        handleRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        rowsElements={rowsElements}
      />
      <DeleteOrganization
        isDialogOpen={Boolean(organizationIdToDelete)}
        organizationId={organizationIdToDelete}
        onCancel={() => {
          setOrganizationIdToDelete('');
        }}
        onSuccess={() => {
          setOrganizationIdToDelete('');
        }}
      />
    </>
  );
};

const NameTableCell = styled(TableCell)({
  fontWeight: 'bold',
});
