import { useContext } from 'react';
import type { FixThisAnyLater } from '@kivra/sdk/types/util/any';
import { GlobalContext } from './globalContext';

export const Debug = (): null => {
  const context = useContext(GlobalContext);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.kivra = window.kivra ?? {};

  window.kivra.inspectGlobalContext = (key?: string) => {
    let obj: FixThisAnyLater = context;
    if (obj && key && key in obj) {
      obj = obj[key];
    } else if (key) {
      console.warn(`"${key}" does not exist in the context`);
    }
    console.group(
      '%c Global Context ',
      'background: rgb(124, 179, 59); color: #fff'
    );
    console.dir(obj);
    console.groupEnd();
    return obj;
  };
  return null;
};
