import React from 'react';
import { Redirect } from 'react-router-dom';
import { isAuthenticated } from '@kivra/sdk/identity';
import { history } from './history';
import { ROUTE_LOGIN } from './Router';

type Props = {
  children?: React.ReactNode;
};

export const AuthenticationBoundary = ({
  children,
}: Props): React.JSX.Element => {
  if (!isAuthenticated()) {
    // If not authenticated user is sent to login for auto login. Then redirected to expected route by onLoginRoute.
    return (
      <Redirect
        to={{
          pathname: ROUTE_LOGIN,
          state: { onLoginRoute: history.location.pathname },
        }}
      />
    );
  }
  return <>{children}</>;
};
