import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoginPage } from '../pages/Login';
import { NotFound } from '../pages/NotFound';
import { ROUTE_LOGIN, ROUTE_START } from './Router';

export const PublicRoutes = (): React.JSX.Element => {
  return (
    <Switch>
      <Redirect exact from="/" to={ROUTE_START} />
      <Route exact path={ROUTE_LOGIN} component={LoginPage} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};
