import { pick } from '@kivra/sdk/common';

export const identityProviderOptions: IdentityProviderOption[] = [
  {
    value: 'google',
    label: 'Google',
    logoPath: '/assets/logo/google_g_logo.svg',
  },
  {
    value: 'kivra_otp',
    label: 'OTP (One Time Password)',
    logoPath: '/assets/logo/kivra_otp_logo.svg',
  },
];

export const getIdpOption = (
  name: string
): { value: string; label: string } => {
  const idp = identityProviderOptions.find(({ value }) => value === name);
  if (!idp) {
    throw new Error(`idp: ${name} has no configuration`);
  }
  return pick(idp, ['value', 'label']);
};

export const getIdpLabel = (id: string): string =>
  identityProviderOptions.find(idp => id === idp.value)?.label ?? id;

export const getIdpLogoPath = (id: string): string | undefined =>
  identityProviderOptions.find(idp => id === idp.value)?.logoPath;

export interface IdentityProviderOption {
  value: string;
  label: string;
  logoPath?: string;
}

export const mapIdpsToOptions = (
  identityProviders: string[]
): Array<{ value: string; label: string }> =>
  identityProviders.map(getIdpOption);
