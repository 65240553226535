import { Body, Button, Heading, Margin } from '@kivra/react-components';
import { CheckmarkCircleIcon } from '@kivra/react-components/icons';
import React from 'react';
import { ModalButtonLayout } from '../../../components/ModalButtonLayout';
import { useCopy } from '../../../globalContext';

type Props = {
  onContinue: () => void;
};

export const CompleteStep: React.FC<Props> = ({ onContinue }) => {
  const getCopy = useCopy();
  return (
    <>
      <ModalButtonLayout>
        <Margin bottom={28}>
          <CheckmarkCircleIcon size={48} />
        </Margin>
        <Heading size="medium">
          {getCopy('sender_backoffice__guide_complete_title')}
        </Heading>
        <Body size="medium">
          {getCopy('sender_backoffice__guide_complete_description')}
        </Body>
        <Button variant="primary" onClick={onContinue}>
          {getCopy('sender_backoffice__go_to_organization')}
        </Button>
      </ModalButtonLayout>
    </>
  );
};
