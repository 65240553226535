import React from 'react';
import { FullPageLoader, Button, styled } from '@kivra/react-components';
import { PlusIcon } from '@kivra/react-components/icons';
import { useOrganizations } from '../../contexts/organizationsContext';
import { goTo } from '../../routes/history';
import { ROUTE_CREATE_ORGANIZATION } from '../../routes/Router';
import { useCopy } from '../../globalContext';
import { ListHeading } from '../../components/ListHeading';
import { useLoggedInUser } from '../../contexts/loggedInUserContext';
import { OrganizationsTable } from './components/OrganizationsTable';

const ButtonLayout = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '$spacing-16',
});

export const OrganizationList = (): React.JSX.Element => {
  const { organizations, isLoading } = useOrganizations();
  const { isAdmin } = useLoggedInUser();

  const getCopy = useCopy();

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <ButtonLayout>
        <ListHeading count={organizations.length}>
          {getCopy('sender_backoffice__organizations')}
        </ListHeading>
        {isAdmin && (
          <Button
            variant="primary"
            onClick={() => goTo(ROUTE_CREATE_ORGANIZATION)}
            dataTestId="CreateOrganizationButton"
          >
            <Button.Icon iconComponent={PlusIcon} />
            {getCopy('sender_backoffice__create_organization')}
          </Button>
        )}
      </ButtonLayout>
      <OrganizationsTable organizations={organizations} />
    </>
  );
};
