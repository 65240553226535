import {
  Body,
  Button,
  Flex,
  Loader,
  Margin,
  useOnMount,
} from '@kivra/react-components';
import { PlusIcon } from '@kivra/react-components/icons';
import { useServiceMessage } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import type { Membership } from '@sender-portal-fe/util-shared/src/sdk/personas/types/membership';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { DeleteDialog } from '../../../components/DeleteDialog';
import { ListHeading } from '../../../components/ListHeading';
import { useOrganization } from '../../../contexts/organizationContext';
import { useCopy } from '../../../globalContext';
import { ROUTE_INVITE_USER } from '../../../routes/Router';
import { goTo } from '../../../routes/history';
import {
  getOrganizationMembers,
  removeUserFromOrganization,
} from '../../../util/personasRequests';
import { MembersTable } from './MembersTable';

export const Members: React.FC = () => {
  const { organization } = useOrganization();
  const [isLoading, setIsLoading] = useState(true);
  const [members, setMembers] = useState<Membership[]>([]);
  const [memberIdToRemove, setMemberIdToRemove] = useState('');
  const { addServiceMessage } = useServiceMessage();
  const { url } = useRouteMatch();
  const getCopy = useCopy();

  useOnMount(async () => {
    const members = await getOrganizationMembers(organization.id);
    setMembers(members);
    setIsLoading(false);
  });

  const handleDeleteUser = async (member: string): Promise<void> => {
    try {
      await removeUserFromOrganization(memberIdToRemove, organization.id);
      setMembers(members =>
        members.filter(({ id }) => id !== memberIdToRemove)
      );
      setMemberIdToRemove('');
      addServiceMessage({
        title: getCopy('sender_backoffice__action_feedback_delete_from', {
          thing: member,
          organizationName: organization.name,
        }),
        body: '',
        variant: 'success',
        fadeout: true,
      });
    } catch {
      setMemberIdToRemove('');
      addServiceMessage({
        title: getCopy('error_generic__title'),
        body: '',
        variant: 'error',
      });
    }
  };

  const hasNoMembers = members.length === 0;

  const memberToRemove =
    members.find(({ id }) => id === memberIdToRemove)?.idFieldValue ??
    memberIdToRemove;
  const removeMemberDialogText = getCopy(
    'sender_backoffice__remove_from_dialog',
    { remove: memberToRemove, from: organization.name }
  );

  return (
    <>
      <Margin bottom={16}>
        <Flex justifyContent="space-between" alignItems="center">
          <ListHeading count={isLoading ? undefined : members.length}>
            {getCopy('sender_backoffice__users')}
          </ListHeading>
          <Button
            variant="secondary"
            onClick={() => goTo(`${url}${ROUTE_INVITE_USER}`)}
            disabled={organization.idProviders.length === 0}
          >
            <Button.Icon iconComponent={PlusIcon} />
            {getCopy('sender_backoffice__invite_user')}
          </Button>
        </Flex>
      </Margin>
      <Flex justifyContent="center">
        {isLoading ? (
          <Loader />
        ) : hasNoMembers ? (
          <Body size="medium">
            {getCopy('sender_backoffice__members_table_zero_state')}
          </Body>
        ) : (
          <MembersTable
            members={members}
            handleRemoveMember={(id: string) => setMemberIdToRemove(id)}
          />
        )}
      </Flex>
      <DeleteDialog
        onConfirm={() => {
          return handleDeleteUser(memberToRemove);
        }}
        onCancel={() => {
          setMemberIdToRemove('');
        }}
        text={removeMemberDialogText}
        open={Boolean(memberIdToRemove)}
      />
    </>
  );
};
