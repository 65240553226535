/**
 * Taken (almost) directly from https://github.com/kivra/tf-contsvc-sndr-ptl/blob/474a4108e4cfc74add64fc7979f8b7dfe17270cc/frontend/packages/backoffice/src/pages/organization/components/IDPSelect.tsx
 */

import React from 'react';
import { Select, styled } from '@kivra/react-components';
import { useCopy } from '../../../globalContext';
import {
  mapIdpsToOptions,
  getIdpLogoPath,
} from '../../../util/identityProviders';
import { IDPOption } from './IDPOption';

type Props = {
  disabled?: boolean;
  options: string[];
  value: string;
  onChange: (idpId: string) => void;
};

export const IDPSelect: React.FC<Props> = ({
  disabled,
  options,
  value,
  onChange,
}) => {
  const getCopy = useCopy();
  const idpOptions = mapIdpsToOptions(options);

  return (
    <>
      <Select
        size="medium"
        disabled={disabled}
        value={value}
        onChange={onChange}
        label={getCopy('sender_backoffice__choose_auth_method')}
        options={idpOptions}
        ValueComponent={({ option }) =>
          option ? (
            <IDPOption
              label={option.label}
              value={option.value}
              logoPath={getIdpLogoPath(option.value)}
            />
          ) : (
            <div />
          )
        }
        OptionComponent={({ option, active, selected }) => (
          <MenuOption active={active} selected={selected}>
            <IDPOption
              label={option.label}
              value={option.value}
              logoPath={getIdpLogoPath(option.value)}
            />
          </MenuOption>
        )}
      />
    </>
  );
};

const MenuOption = styled.div<{ active?: boolean; selected?: boolean }>(
  ({ selected, active }) => ({
    backgroundColor: active ? '$hover-background' : undefined,
    padding: [0, '$spacing-20'],
    '& p': {
      fontWeight: selected ? 'bold' : undefined,
    },
    cursor: 'pointer',
  })
);
