import {
  ErrorBoundary,
  FullPageLoader,
  useOnMount,
} from '@kivra/react-components';
import { captureException } from '@kivra/sdk/log';
import { getDefinedValue } from '@sender-portal-shared/sdk/src/assert-defined';
import React, { useReducer } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { initState, reducer } from './appState';
import { bootstrap } from './bootstrap';
import { getLocalCopy } from './bootstrap/copy';
import { GlobalContextProvider } from './globalContext';

function RootApp(): React.JSX.Element {
  const [{ appOptions, config, copy, bootError, heimdall }, dispatch] =
    useReducer(reducer, initState);

  useOnMount(async () => {
    try {
      const { appOptions, config, heimdallQueryParameters } = await bootstrap();
      getLocalCopy()
        .then(copy => dispatch({ type: 'update_copy', copy }))
        .catch((error: unknown) =>
          dispatch({ type: 'set_boot_error', error: error as Error })
        );
      dispatch({ type: 'update_config', config });
      dispatch({ type: 'update_heimdall', heimdall: heimdallQueryParameters });
      dispatch({ type: 'update_app_options', appOptions });
    } catch (error) {
      dispatch({ type: 'set_boot_error', error: error as Error });
    }
  });

  if (bootError) {
    // Will throw and let ErrorBoundary take care of it
    throw bootError;
  }

  if (!appOptions || !config || !copy) {
    return <FullPageLoader />;
  }

  return (
    <GlobalContextProvider
      value={{
        config,
        copy,
        appOptions,
        global: window,
        heimdall,
      }}
    >
      <App />
    </GlobalContextProvider>
  );
}

function run(): void {
  const rootElement = document.getElementById('root');
  const root = createRoot(getDefinedValue(rootElement));

  root.render(
    <ErrorBoundary
      onCatch={e => {
        captureException(e);
      }}
    >
      <RootApp />
    </ErrorBoundary>
  );
}

run();
