import type { PersonaOrganization } from '@sender-portal-fe/util-shared/src/sdk/personas/types/personaOrganization';
import { createContext, useContext } from 'react';
import type { NamedSender } from '../types';
import type { OrganizationBase } from './organizationsContext';

export interface DetailedOrganization
  extends OrganizationBase,
    PersonaOrganization {
  senders: NamedSender[];
}

interface OrganizationContext {
  organization?: DetailedOrganization;
  isLoading: boolean;
  setOrganization: (organization: DetailedOrganization) => void;
}

const OrganizationContext = createContext<OrganizationContext>({
  isLoading: false,
  setOrganization: (organization: DetailedOrganization) =>
    console.error('setOrganization not implemented', organization),
});

const { Provider: OrganizationContextProvider } = OrganizationContext;

const hookCreator =
  <T>(map: (context: OrganizationContext) => T) =>
  (): T => {
    const organizations = useContext(OrganizationContext);
    return map(organizations);
  };

export const useOrganization = hookCreator(
  ({ organization, isLoading, setOrganization }) => {
    if (!organization) {
      throw new Error();
    }
    return {
      organization,
      isLoading,
      setOrganization,
    };
  }
);

export { OrganizationContextProvider };
