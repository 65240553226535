import React, { useState } from 'react';
import {
  TextField,
  Margin,
  Button,
  FormItemLabel,
} from '@kivra/react-components';
import { useServiceMessage } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import { useCopy } from '../../../globalContext';
import { SenderSearch } from '../../../components/senderSearch/SenderSearch';
import { ModalButtonLayout } from '../../../components/ModalButtonLayout';
import { goTo } from '../../../routes/history';
import { ROUTE_ORGANIZATION_LIST } from '../../../routes/Router';
import { createOrganization } from '../../../util/senderRequests';
import { Form } from '../../../components/Form';
import { StepHeading } from './StepHeading';

type Props = {
  onOrganizationCreated: (
    organizationId: string,
    organizationName: string
  ) => void;
};

export const CreateOrganizationStep: React.FC<Props> = ({
  onOrganizationCreated,
}) => {
  const getCopy = useCopy();
  const [name, setName] = useState('');
  const [tenantKeys, setTenantIds] = useState<string[]>([]);
  const { addServiceMessage } = useServiceMessage();
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateOrganization = (): void => {
    setIsLoading(true);
    createOrganization(name.trim(), tenantKeys)
      .then(({ organizationId }) => {
        setIsLoading(false);
        addServiceMessage({
          title: getCopy(
            'sender_backoffice__action_feedback_create_organization',
            { name }
          ),
          body: '',
          variant: 'success',
          fadeout: true,
        });
        onOrganizationCreated(organizationId, name);
      })
      .catch(() => {
        setIsLoading(false);
        addServiceMessage({
          title: getCopy('error_generic__title'),
          body: '',
          variant: 'error',
        });
      });
  };
  return (
    <>
      <StepHeading>
        {getCopy('sender_backoffice__create_new_organization')}
      </StepHeading>
      <Form onSubmit={handleCreateOrganization}>
        <TextField
          label={getCopy('sender_backoffice__members_column_name')}
          autoFocus
          onChange={e => setName(e.target.value)}
          value={name}
        />
        <Margin top={32}>
          <FormItemLabel titleText={getCopy('sender_backoffice__senders')} />
          <SenderSearch
            placeholder={getCopy('sender_backoffice__search_for_senders')}
            onChange={tenants => setTenantIds(tenants.map(({ key }) => key))}
          />
        </Margin>
        <Margin top={56}>
          <ModalButtonLayout>
            <Button
              variant="primary"
              type="submit"
              disabled={name.length == 0}
              progress={isLoading}
            >
              {getCopy('sender_backoffice__create_organization')}
            </Button>
            <Button
              variant="link"
              onClick={() => goTo(ROUTE_ORGANIZATION_LIST)}
            >
              {getCopy('btn__cancel_dialog')}
            </Button>
          </ModalButtonLayout>
        </Margin>
      </Form>
    </>
  );
};
