import { styled } from '@kivra/react-components';

export const TopLeftPosition = styled.div({
  position: 'absolute',
  left: '$spacing-24',
  top: '$responsive-topbar-height',
  $medium: {
    left: '$spacing-32',
  },
});
