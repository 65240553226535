import { createContext, useContext } from 'react';
import type { LoggedInUser } from '../types';

interface LoggedInUsersContext {
  loggedInUser: LoggedInUser | null;
}

const loggedInUsersContext = createContext<LoggedInUsersContext>({
  loggedInUser: null,
});

const { Provider: LoggedInUserContextProvider } = loggedInUsersContext;

const hookCreator = <T>(map: (context: LoggedInUsersContext) => T) => (): T => {
  const loggedInUsers = useContext(loggedInUsersContext);
  return map(loggedInUsers);
};

export const useLoggedInUser = hookCreator(({ loggedInUser }) => {
  if (!loggedInUser) {
    throw new Error('No logged in user context');
  }
  return loggedInUser;
});

export { LoggedInUserContextProvider };
