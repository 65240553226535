import { Body, Button, Flex, Margin } from '@kivra/react-components';
import { PlusIcon } from '@kivra/react-components/icons';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useServiceMessage } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import { DeleteDialog } from '../../../components/DeleteDialog';
import { ListHeading } from '../../../components/ListHeading';
import { useLoggedInUser } from '../../../contexts/loggedInUserContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useCopy } from '../../../globalContext';
import { ROUTE_ADD_SENDER } from '../../../routes/Router';
import { goTo } from '../../../routes/history';
import { removeSenderFromOrganization } from '../../../util/senderRequests';
import { SendersTable } from './SendersTable';

export const Senders: React.FC = () => {
  const { organization, setOrganization } = useOrganization();
  const { isAdmin } = useLoggedInUser();

  const [senderKeyToRemove, setSenderKeyToRemove] = useState('');
  const { addServiceMessage } = useServiceMessage();
  const getCopy = useCopy();
  const { url } = useRouteMatch();

  const hasNoSenders = organization.senders.length === 0;

  const senderToRemove =
    organization.senders.find(({ key }) => key === senderKeyToRemove)?.name ??
    senderKeyToRemove;
  const removeSenderDialogText = getCopy(
    'sender_backoffice__remove_from_dialog',
    { remove: senderToRemove, from: organization.name }
  );

  const handleRemoveSender = async (senderText: string): Promise<void> => {
    try {
      await removeSenderFromOrganization(senderKeyToRemove, organization.id);
      setOrganization({
        ...organization,
        senders: organization.senders.filter(
          ({ key }) => key !== senderKeyToRemove
        ),
      });
      setSenderKeyToRemove('');
      addServiceMessage({
        title: getCopy('sender_backoffice__action_feedback_delete_from', {
          thing: senderText,
          organizationName: organization.name,
        }),
        body: '',
        variant: 'success',
        fadeout: true,
      });
    } catch {
      setSenderKeyToRemove('');
      addServiceMessage({
        title: getCopy('error_generic__title'),
        body: '',
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Margin top={42} bottom={16}>
        <Flex justifyContent="space-between" alignItems="center">
          <ListHeading count={organization.senders.length}>
            {getCopy('sender_backoffice__senders')}
          </ListHeading>
          {isAdmin && (
            <Button
              variant="secondary"
              onClick={() => goTo(`${url}${ROUTE_ADD_SENDER}`)}
            >
              <Button.Icon iconComponent={PlusIcon} />
              {getCopy('sender_backoffice__add_sender')}
            </Button>
          )}
        </Flex>
      </Margin>
      <Flex justifyContent="center">
        {hasNoSenders ? (
          <Body size="medium">
            {getCopy('sender_portal__no_sender_in_org')}
          </Body>
        ) : (
          <SendersTable
            senders={organization.senders}
            handleRemoveSender={(senderKey: string) => {
              setSenderKeyToRemove(senderKey);
            }}
          />
        )}
      </Flex>
      <DeleteDialog
        onConfirm={() => {
          return handleRemoveSender(senderToRemove);
        }}
        onCancel={() => {
          setSenderKeyToRemove('');
        }}
        text={removeSenderDialogText}
        open={Boolean(senderKeyToRemove)}
      />
    </>
  );
};
