import {
  convertPropertyNamesToCamelCase,
  senderRequest,
} from '@kivra/sdk/common';
import { userSession } from '@kivra/sdk/identity';
import type { LegitimateAny } from '@kivra/sdk/types/util/any';
import type { Membership } from './types/membership.js';
import type { PersonaOrganization } from './types/personaOrganization.js';

export const getPersonasOrganization = (
  organizationId: string
): Promise<PersonaOrganization> => {
  return senderRequest
    .get<LegitimateAny>({
      path: `/v1/organization/${organizationId}/id_providers`,
      accessToken: userSession.getToken(),
    })
    .then(
      response =>
        convertPropertyNamesToCamelCase(response) as PersonaOrganization
    );
};

export const getOrganizationMembers = (
  organizationId: string
): Promise<Membership[]> => {
  return senderRequest
    .get<LegitimateAny[]>({
      path: `/v1/organization/${organizationId}/members`,
      accessToken: userSession.getToken(),
    })
    .then((response: LegitimateAny[]) =>
      response.map((membership: LegitimateAny) =>
        convertPropertyNamesToCamelCase(membership)
      )
    );
};

export const inviteUserToOrganization = (
  organizationId: string,
  email: string,
  identityProvider: string,
  organizationName: string
): Promise<{ id: string }> => {
  return senderRequest.post({
    path: `/v1/organization/${organizationId}/members`,
    accessToken: userSession.getToken(),
    payload: {
      email,
      identity_provider: identityProvider,
      organization_name: organizationName,
    },
  });
};

export const removeUserFromOrganization = (
  membershipId: string,
  organizationId: string
): Promise<void> =>
  senderRequest.delete({
    path: `/v1/organization/${organizationId}/member/${membershipId}`,
    accessToken: userSession.getToken(),
  });

export const setOrganizationIdentityProviders = (
  identityProviderIds: string[],
  organizationId: string
): Promise<void> =>
  senderRequest.put({
    path: `/v1/organization/${organizationId}/id_providers`,
    payload: {
      id_providers: identityProviderIds,
    },
    accessToken: userSession.getToken(),
  });
