import { KivraTheme } from '@kivra/react-components';
import React from 'react';
import { Debug } from './Debug';
import { Router } from './routes/Router';

export const App = React.memo(() => {
  return (
    <KivraTheme>
      <Debug />
      <Router />
    </KivraTheme>
  );
});
