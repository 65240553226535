import type { Organization } from '@sender-portal-fe/util-shared/src/sdk/senders/types/organization';
import { createContext, useContext } from 'react';

export type OrganizationBase = Organization;
interface OrganizationsContext {
  organizations: OrganizationBase[];
  isLoading: boolean;
  setOrganizations: (organizations: OrganizationBase[]) => void;
}

const OrganizationsContext = createContext<OrganizationsContext>({
  organizations: [],
  isLoading: false,
  setOrganizations: organizations => {
    console.log('setOrganizations() not implemented', organizations);
  },
});

const { Provider: OrganizationsContextProvider } = OrganizationsContext;

const hookCreator =
  <T>(map: (context: OrganizationsContext) => T) =>
  (): T => {
    const organizations = useContext(OrganizationsContext);
    return map(organizations);
  };

export const useOrganizations = hookCreator(
  ({ organizations, isLoading, setOrganizations }) => ({
    organizations,
    setOrganizations,
    isLoading,
  })
);

export { OrganizationsContextProvider };
