import {
  Button,
  Heading,
  Margin,
  styled,
  TextField,
} from '@kivra/react-components';
import { ApiResponseError, emailPattern } from '@kivra/sdk/common';
import { useServiceMessage } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import React, { useState } from 'react';
import { Form } from '../../components/Form';
import { ModalButtonLayout } from '../../components/ModalButtonLayout';
import { NarrowLayout } from '../../components/NarrowLayout';
import { OrganizationDialogHeader } from '../../components/OrganizationDialogHeader';
import { Card } from '../../components/card/Card';
import { CardPadding } from '../../components/card/CardPadding';
import { useOrganization } from '../../contexts/organizationContext';
import { useCopy } from '../../globalContext';
import { getParentPath, goBack } from '../../routes/history';
import { inviteUserToOrganization } from '../../util/personasRequests';
import { IDPSelect } from './components/IDPSelect';

export const Invite = (): React.JSX.Element => {
  const { organization } = useOrganization();

  if (organization.idProviders.length === 0) {
    throw new Error('`organization.idProviders` is empty');
  }

  const [email, setEmail] = useState('');
  const [identityProvider, setIdentityProvider] = useState(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    organization.idProviders[0]!
  );
  const isValidEmail = emailPattern.test(email);
  const getCopy = useCopy();
  const { addServiceMessage } = useServiceMessage();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (): void => {
    setIsLoading(true);
    inviteUserToOrganization(
      organization.id,
      email,
      identityProvider,
      organization.name
    )
      .then(() => {
        addServiceMessage({
          title: getCopy('sender_backoffice__action_feedback_add_to', {
            thing: email,
            organizationName: organization.name,
          }),
          body: '',
          variant: 'success',
          fadeout: true,
        });
      })
      .catch((error: unknown) => {
        const errorBody = ApiResponseError.isMemberAlreadyExistsError(error)
          ? getCopy('sender_backoffice__member_already_exists')
          : getCopy('sender_backoffice__user_invite_failed');
        addServiceMessage({
          title: getCopy('error_generic__title'),
          body: errorBody,
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
        goBack(getParentPath());
      });
  };

  return (
    <NarrowLayout>
      <Card data-test-id="InviteUserCard">
        <CardPadding>
          <OrganizationDialogHeader organization={organization} />
          <Heading size="medium">
            {getCopy('sender_backoffice__invite_user')}
          </Heading>
          <Form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              label={getCopy('sender_backoffice__members_column_email')}
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
            <Margin top={24} bottom={40}>
              <IDPSelect
                options={organization.idProviders}
                onChange={(idpId: string) => setIdentityProvider(idpId)}
                value={identityProvider}
              />
            </Margin>
            <ModalButtonLayout>
              <InviteButton
                progress={isLoading}
                disabled={!email || !isValidEmail || !identityProvider}
                variant="primary"
                type="submit"
              >
                {getCopy('button__invite')}
              </InviteButton>
              <Button variant="link" onClick={() => goBack(getParentPath())}>
                {getCopy('btn__cancel_dialog')}
              </Button>
            </ModalButtonLayout>
          </Form>
        </CardPadding>
      </Card>
    </NarrowLayout>
  );
};

const InviteButton = styled(Button)({
  marginTop: '$spacing-24',
  alignSelf: 'center',
});
