import React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { AccessDenied } from '../components/AccessDenied';
import { useLoggedInUser } from '../contexts/loggedInUserContext';

export const AdminRoute = ({
  children,
  ...props
}: RouteProps): React.JSX.Element => {
  const { isAdmin } = useLoggedInUser();
  if (!isAdmin) {
    return <AccessDenied />;
  }
  return <Route {...props}>{children}</Route>;
};
