import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ServiceMessageProvider } from '@sender-portal-fe/util-shared/src//components/ServiceMessageProvider';
import { OrganizationList } from '../pages/organizationList/OrganizationList';
import { OrganizationsProvider } from '../contexts/organizationsProvider';
import { Organization } from '../pages/organization/Organization';
import { Invite } from '../pages/invite/Invite';
import { BackofficeTopBar } from '../components/TopBar/BackofficeTopBar';
import { LoggedInUserProvider } from '../contexts/LoggedInUserProvider';
import { CreateOrganizationGuide } from '../pages/createOrganization/CreateOrganizationGuide';
import { OrganizationProvider } from '../contexts/organizationProvider';
import { AddSenders } from '../pages/AddSender';
import { UpdateIdentityProviders } from '../pages/UpdateIdentityProviders';
import { Admin } from '../pages/admin/Admin';
import { AdminOrganizationProvider } from '../contexts/AdminOrganizationProvider';
import { BaseLayout } from '../components/BaseLayout';
import { useCopy } from '../globalContext';
import {
  ROUTE_INVITE_USER,
  ROUTE_ORGANIZATION_LIST,
  ROUTE_ORGANIZATION,
  ROUTE_CREATE_ORGANIZATION,
  ROUTE_ADD_SENDER,
  ROUTE_SET_AUTH,
  ROUTE_ADMIN,
  ROUTE_START,
} from './Router';
import { AuthenticationBoundary } from './AuthenticationBoundary';
import { AdminRoute } from './AdminRoute';
import { DefaultRoute } from './DefaultRoute';

export const PrivateRoutes = (): React.JSX.Element => {
  const getCopy = useCopy();

  return (
    <AuthenticationBoundary>
      <LoggedInUserProvider>
        <BackofficeTopBar />
        <BaseLayout data-test-id="PageContent">
          <ServiceMessageProvider getCopy={getCopy}>
            <Switch>
              <Route exact path={ROUTE_START} component={DefaultRoute} />
              <AdminRoute
                exact
                path={ROUTE_CREATE_ORGANIZATION}
                component={CreateOrganizationGuide}
              />
              <AdminRoute path={ROUTE_ADMIN}>
                <AdminOrganizationProvider>
                  <Switch>
                    <AdminRoute exact path={ROUTE_ADMIN} component={Admin} />
                    <AdminRoute
                      exact
                      path={`${ROUTE_ADMIN}${ROUTE_INVITE_USER}`}
                      component={Invite}
                    />
                  </Switch>
                </AdminOrganizationProvider>
              </AdminRoute>
              <Route path={ROUTE_ORGANIZATION_LIST}>
                <OrganizationsProvider>
                  <Switch>
                    <Route
                      exact
                      path={ROUTE_ORGANIZATION_LIST}
                      component={OrganizationList}
                    />
                    <Route
                      path={`${ROUTE_ORGANIZATION_LIST}${ROUTE_ORGANIZATION}`}
                    >
                      <OrganizationProvider>
                        <Switch>
                          <Route
                            exact
                            path={`${ROUTE_ORGANIZATION_LIST}${ROUTE_ORGANIZATION}`}
                            component={Organization}
                          />
                          <Route
                            exact
                            path={`${ROUTE_ORGANIZATION_LIST}${ROUTE_ORGANIZATION}${ROUTE_INVITE_USER}`}
                            component={Invite}
                          />
                          <AdminRoute
                            exact
                            path={`${ROUTE_ORGANIZATION_LIST}${ROUTE_ORGANIZATION}${ROUTE_ADD_SENDER}`}
                            component={AddSenders}
                          />
                          <Route
                            exact
                            path={`${ROUTE_ORGANIZATION_LIST}${ROUTE_ORGANIZATION}${ROUTE_SET_AUTH}`}
                            component={UpdateIdentityProviders}
                          />
                        </Switch>
                      </OrganizationProvider>
                    </Route>
                  </Switch>
                </OrganizationsProvider>
              </Route>
            </Switch>
          </ServiceMessageProvider>
        </BaseLayout>
      </LoggedInUserProvider>
    </AuthenticationBoundary>
  );
};
