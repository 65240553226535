import { Margin, styled, TopBar } from '@kivra/react-components';
import { UserIcon } from '@sender-portal-fe/util-shared/src/components/backoffice/UserIcon';
import React from 'react';
import { useLoggedInUser } from '../../contexts/loggedInUserContext';
import { goTo } from '../../routes/history';
import { ROUTE_ORGANIZATION_LIST } from '../../routes/Router';
import { TopBarMenu } from './TopBarMenu';

const Layout = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  height: '$responsive-topbar-height',
  color: '$text-primary',
});

export const BackofficeTopBar = (): React.JSX.Element => {
  const loggedInUser = useLoggedInUser();
  const email = loggedInUser.email;

  return (
    <TopBar
      logoHref={ROUTE_ORGANIZATION_LIST}
      alignLogo="left"
      logoText="Sender Backoffice"
      onClickLogo={() => goTo(ROUTE_ORGANIZATION_LIST)}
    >
      {email && (
        <Layout>
          <UserIcon email={email} />
          <Margin left={12}>{loggedInUser.email}</Margin>
          <TopBarMenu />
        </Layout>
      )}
    </TopBar>
  );
};
