import React from 'react';

type Props = {
  url: string;
  size?: number;
};

export const SenderIcon: React.FC<Props> = ({ url, size = 40 }) => {
  return <img src={url} width={size} height={size} />;
};
