import React from 'react';
import { Margin, Heading } from '@kivra/react-components';

type Props = {
  children?: React.ReactNode;
};

export const StepHeading = ({ children }: Props): React.JSX.Element => (
  <Margin bottom={4} style={{ alignSelf: 'center' }}>
    <Heading size="medium">{children}</Heading>
  </Margin>
);
