import type { LegitimateAny } from '@kivra/sdk/types/util/any';
import { useState, useRef, useEffect } from 'react';

export const useRefState = (
  initialValue: LegitimateAny
): [
  LegitimateAny,
  React.MutableRefObject<LegitimateAny>,
  React.Dispatch<LegitimateAny>,
] => {
  const [state, setState] = useState(initialValue);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  return [state, stateRef, setState];
};
