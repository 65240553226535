import React, { useState } from 'react';
import { Margin, Button, TextField } from '@kivra/react-components';
import { useServiceMessage } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import { useCopy } from '../../../globalContext';
import { ModalButtonLayout } from '../../../components/ModalButtonLayout';
import { IDPSelect } from '../../invite/components/IDPSelect';
import { inviteUserToOrganization } from '../../../util/personasRequests';
import { Form } from '../../../components/Form';
import { StepHeading } from './StepHeading';

type Props = {
  organizationId: string;
  organizationName: string;
  identityProviderIds: string[];
  onCancel: () => void;
  onSuccess: () => void;
};

export const AddUsersStep: React.FC<Props> = ({
  onCancel,
  organizationId,
  organizationName,
  onSuccess,
  identityProviderIds,
}) => {
  if (identityProviderIds.length === 0) {
    throw new Error('`identityProviderIds` is empty');
  }

  const getCopy = useCopy();
  const { addServiceMessage } = useServiceMessage();
  const [email, setEmail] = useState('');
  const [identityProvider, setIdentityProvider] = useState(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    identityProviderIds[0]!
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleInviteUser = (): void => {
    setIsLoading(true);
    inviteUserToOrganization(
      organizationId,
      email,
      identityProvider,
      organizationName
    )
      .then(() => {
        setIsLoading(false);
        addServiceMessage({
          title: getCopy('sender_backoffice__action_feedback_add_to', {
            thing: email,
            organizationName,
          }),
          body: '',
          variant: 'success',
          fadeout: true,
        });
        onSuccess();
      })
      .catch(() => {
        setIsLoading(false);
        addServiceMessage({
          title: getCopy('error_generic__title'),
          body: '',
          variant: 'error',
        });
      });
  };

  return (
    <>
      <StepHeading>{getCopy('sender_backoffice__invite_user')}</StepHeading>
      <Form onSubmit={handleInviteUser}>
        <TextField
          autoFocus
          label={getCopy('sender_backoffice__members_column_email')}
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
        <Margin top={24}>
          <IDPSelect
            options={identityProviderIds}
            onChange={(idpId: string) => setIdentityProvider(idpId)}
            value={identityProvider}
          />
        </Margin>
        <Margin top={56}>
          <ModalButtonLayout>
            <Button
              progress={isLoading}
              variant="primary"
              disabled={!identityProvider || !email}
              type="submit"
            >
              {getCopy('button__invite')}
            </Button>
            <Button variant="link" onClick={onCancel}>
              {getCopy('sender_backoffice__skip_guide_step')}
            </Button>
          </ModalButtonLayout>
        </Margin>
      </Form>
    </>
  );
};
