import { ErrorPage } from '@kivra/react-components';
import React from 'react';
import { useCopy } from '../globalContext';

export const AccessDenied = (): React.JSX.Element => {
  const getCopy = useCopy();
  return (
    <ErrorPage
      title={getCopy('sender_backoffice__access_denied')}
      body={getCopy('sender_backoffice__access_denied_body')}
    />
  );
};
