import React, { useState, useEffect } from 'react';
import { FullPageLoader, ErrorPage } from '@kivra/react-components';
import { useRouteMatch } from 'react-router-dom';
import { getPersonasOrganization } from '../util/personasRequests';
import { getOrganizationSenders } from '../util/senderRequests';
import type { RouteParams } from '../routes/Router';
import { useOrganizations } from './organizationsContext';
import type { DetailedOrganization } from './organizationContext';
import { OrganizationContextProvider } from './organizationContext';

type Props = {
  children?: React.ReactNode;
};

export const OrganizationProvider = ({
  children,
}: Props): React.JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { organizations, setOrganizations } = useOrganizations();
  const [organization, setOrganization] = useState<DetailedOrganization>();
  const {
    params: { organizationId },
  } = useRouteMatch<RouteParams>();

  const updateOrganization = (org: DetailedOrganization): void => {
    setOrganization(org);
    setOrganizations(
      organizations.map(oldOrg =>
        oldOrg.id === org.id ? { ...org, name: org.name.trim() } : oldOrg
      )
    );
  };

  useEffect(() => {
    if (organizationId) {
      setIsLoading(true);
      setHasError(false);
      Promise.all([
        getPersonasOrganization(organizationId),
        getOrganizationSenders(organizationId),
      ])
        .then(([organization, senders]) => {
          const organizationBase = organizations.find(
            ({ id }) => id === organizationId
          );
          if (!organizationBase) {
            throw new Error();
          }
          setOrganization({
            ...organizationBase,
            ...organization,
            senders: senders.filter(({ type }) =>
              ['tenant', 'store'].includes(type)
            ),
          });
        })
        .catch(() => {
          setHasError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [organizationId]);

  if (hasError && !isLoading) {
    return <ErrorPage useDefaultTexts />;
  }

  return (
    <OrganizationContextProvider
      value={{ organization, isLoading, setOrganization: updateOrganization }}
    >
      {isLoading ? <FullPageLoader /> : children}
    </OrganizationContextProvider>
  );
};
