import { useOnMount } from '@kivra/react-components';
import { useLoggedInUser } from '../contexts/loggedInUserContext';
import { goTo } from './history';
import { ROUTE_ORGANIZATION_LIST } from './Router';

export const DefaultRoute = (): null => {
  const { isAdmin, organizationIds } = useLoggedInUser();
  useOnMount(() => {
    if (isAdmin || organizationIds.length > 1) {
      goTo(ROUTE_ORGANIZATION_LIST);
    } else {
      goTo(`${ROUTE_ORGANIZATION_LIST}/${organizationIds[0]}`);
    }
  });
  return null;
};
