import type { Order, TableColumnData } from '@kivra/react-components';
import {
  Flex,
  Margin,
  MenuItem,
  Table,
  TableCell,
  TableRow,
} from '@kivra/react-components';
import { TrashIcon } from '@kivra/react-components/icons';
import { UserIcon } from '@sender-portal-fe/util-shared/src/components/backoffice/UserIcon';
import type { Membership } from '@sender-portal-fe/util-shared/src/sdk/personas/types/membership';
import React, { useEffect, useState } from 'react';
import { ContextMenu } from '../../../components/contextMenu/ContextMenu';
import {
  ContextMenuTableCell,
  NameTableCell,
  getSorting,
  stableSort,
} from '../../../components/table';
import { useCopy } from '../../../globalContext';
import { getIdpLabel } from '../../../util/identityProviders';

interface Props {
  members: Membership[];
  handleRemoveMember: (id: string) => void;
}

enum SortableTableColumn {
  email = 'email',
  idp = 'idp',
  status = 'status',
}

type RowData = {
  id: string;
  [SortableTableColumn.email]: string;
  [SortableTableColumn.idp]: string;
  [SortableTableColumn.status]: string;
};

const memberToRow = ({
  idFieldValue,
  id,
  idProvider,
}: Membership): RowData => ({
  id: id,
  [SortableTableColumn.email]: idFieldValue,
  [SortableTableColumn.idp]: getIdpLabel(idProvider),
  [SortableTableColumn.status]: '',
});

export const MembersTable: React.FC<Props> = ({
  members,
  handleRemoveMember,
}) => {
  const [order, setOrder] = useState<Order>('ascending');
  const [orderBy, setOrderBy] = useState(SortableTableColumn.email);
  const [rows, setRows] = useState<RowData[]>([]);
  const getCopy = useCopy();

  useEffect(() => {
    const rows = members.map(memberToRow);
    setRows(stableSort<RowData>(rows, getSorting<string>(order, orderBy)));
  }, [members]);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: SortableTableColumn
  ): void => {
    const isDesc = orderBy === property && order === 'descending';
    const newOrder = isDesc ? 'ascending' : 'descending';
    setOrder(newOrder);
    setOrderBy(property);
    setRows(stableSort(rows, getSorting<string>(newOrder, property)));
  };

  const tableColumns: Array<TableColumnData<SortableTableColumn>> = [
    {
      id: SortableTableColumn.email,
      title: getCopy('sender_backoffice__members_column_email'),
      sortable: true,
    },
    {
      id: SortableTableColumn.idp,
      title: getCopy('sender_backoffice__identity_provider'),
      sortable: true,
    },
    { id: 'contextMenu' },
  ];

  const rowsElements = rows.map(rowData => (
    <TableRow key={rowData.id}>
      <NameTableCell>
        <Flex alignItems="center">
          <Margin right={24}>
            <UserIcon email={rowData.email} />
          </Margin>
          {rowData.email}
        </Flex>
      </NameTableCell>
      <TableCell>{rowData.idp}</TableCell>
      <ContextMenuTableCell>
        <ContextMenu isSubtle>
          {setIsOpen => (
            <MenuItem
              title={getCopy('sender_backoffice__remove_user')}
              onClick={() => {
                setIsOpen(false);
                handleRemoveMember(rowData.id);
              }}
              size="small"
              icon={TrashIcon}
            />
          )}
        </ContextMenu>
      </ContextMenuTableCell>
    </TableRow>
  ));

  return (
    <>
      <Table<SortableTableColumn>
        columns={tableColumns}
        handleRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        rowsElements={rowsElements}
        dataTestId="MembersTable"
      />
    </>
  );
};
