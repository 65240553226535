import { createBrowserHistory } from 'history';

export interface HistoryState {
  previousRoute?: string;
  onLoginRoute?: string;
}

export const getOnLoginRoute = (): string | undefined => {
  return history.location.state?.onLoginRoute;
};

export const getPreviousRoute = (): string | undefined => {
  return history.location.state?.previousRoute;
};

export const goBack = (fallbackRoute: string): void => {
  const previousRoute = getPreviousRoute();
  if (previousRoute) {
    history.goBack();
  } else {
    history.push(fallbackRoute);
  }
};

export const goTo = (
  path: string,
  options?: Pick<HistoryState, 'onLoginRoute'>
): void => {
  history.push(path, {
    previousRoute: history.location.pathname,
    ...options,
  });
};

export const goUp = (): void => {
  goTo(getParentPath());
};

export const getParentPath = (): string =>
  history.location.pathname.split('/').slice(0, -1).join('/');

export const history = createBrowserHistory<HistoryState | undefined>();
